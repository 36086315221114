<template>
  <TicketCategories
    v-if="renderTicketCategories"
    :loaded-promo-code="promoCode"
  />
</template>

<script>
import TicketCategories from './TicketCategories'
import { mapActions, mapState } from 'vuex'
import { getCookieValue } from '../helpers/cookies'
import { segmentPageView } from '../services/segmentTracking'

export default {
  components: { TicketCategories },
  data() {
    return {
      promoCode: null,
    }
  },
  computed: {
    renderTicketCategories() {
      return this.$store.state.ticketCategories.length > 0
    },
    ...mapState(['headEventSlug', 'validPromoCode']),
  },
  mounted() {
    setTimeout(function () {
      segmentPageView('Tickets: TicketsChooser')
    }, 500)
  },
  methods: mapActions(['fetchHeadEvent', 'fetchTicketCategories']),
  created() {
    this.$router.onReady(() => {
      const code = this.validPromoCode || this.$route.query.promo_code
      const eventSlug = this.$route.params.event
      this.promoCode = code

      if (eventSlug) {
        this.fetchHeadEvent(eventSlug)
          .catch(() => {
            this.$store.commit('setGenericError', null)
            this.$router.push({ name: 'PageNotFound' })
          })
          .then(() => {
            this.fetchTicketCategories(this.promoCode).catch((error) => {
              this.$store.commit('clearTicketsByCategory')
              this.$store.commit('setTicketCategories', [])
              if (error.response && error.response.status === 404) {
                const message = error.response.data.errors[0].details
                this.$store.commit('setGenericError', message)
              }
              this.$store.commit('setLoading', false)
            })
          })
      } else {
        this.$router.push({ name: 'PageNotFound' })
      }
    })
  },
}
</script>
