import api from '../services/api'

export const validEmailForEvent = (store, i18n) => {
  return {
    getMessage: i18n.t('validations.custom.emailNotUnique'),
    validate: (email) => {
      var allOrderMails = []

      for (let categoryIndex in store.state.ticketsByCategory) {
        let tickets = store.state.ticketsByCategory[categoryIndex]
        allOrderMails = allOrderMails.concat(
          tickets.map((ticket) => ticket.email),
        )
      }

      const params = {
        email: email,
        emails: allOrderMails,
        event_id: store.state.headEvent.id,
      }

      return api
        .validateEmail(params, store.state)
        .then(() => {
          return true
        })
        .catch(() => {
          return false
        })
    },
  }
}

export const isMultipleOf = (store, i18n) => {
  return {
    getMessage: i18n.t('validations.custom.isNotMultipleOf'),
    validate: (quantity, { inMultiplesOf } = {}) => {
      return quantity % inMultiplesOf === 0
    },
  }
}

export const isMultipleOfParamNames = ['inMultiplesOf']

export const containsNoDigits = (i18n) => {
  return {
    getMessage: (field) => field + ' ' + i18n.t('validations.custom.no_digits'),
    validate: (string) => string.match(/[0-9]+/) == null,
  }
}
