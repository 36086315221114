<template>
  <div>
    {{ $t('pageNotFound.message') }}
    <router-link :to="`/${$i18n.locale}/omr24`"
      >{{ $t('pageNotFound.gotoEvent') }}
    </router-link>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.commit('setLoading', false)
  },
}
</script>
