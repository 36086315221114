<template>
  <TicketPartner
    v-if="ticketPartnerSlug"
    :slug="ticketPartnerSlug"
    :show-invite="true"
    @accepted="handleAccept()"
    @declined="accepted = false"
  />
</template>

<script>
import TicketPartner from './TicketPartner'
import api from '../services/api'

export default {
  components: { TicketPartner },
  data() {
    return {
      accepted: null,
      inviteeToken: null,
      ticketPartnerSlug: null,
    }
  },
  watch: {
    accepted(value) {
      api.setAccepted(this.inviteeToken, value)
    },
  },
  mounted() {
    const token = this.$route.params.token
    if (token) {
      this.inviteeToken = token
      api
        .getInvitee(this.inviteeToken)
        .then((response) => {
          this.$store.commit('setInviteeToken', this.inviteeToken)
          this.ticketPartnerSlug =
            response.data.data.attributes.ticket_partner_slug
          this.handleIsUsed(response.data.data.attributes.used)

          if (this.$route.query.accept) {
            this.accepted = 'true' === this.$route.query.accept
          }
        })
        .catch((error) => {
          if (error.response.status == 404) {
            let locale = this.$i18n.locale
            this.$router.push({ name: 'CodeInvalid', params: { locale } })
          } else {
            this.$router.push({
              name: 'TicketsChooser',
              params: { event: 'omr24' },
            })
          }
        })
    }
  },
  methods: {
    handleIsUsed(value) {
      this.$store.commit('setInviteeIsUsed', value)
      if (value) {
        this.$toasted.error(this.$t('invite.toasts.used'))
      }
    },
    handleAccept() {
      this.accepted = true
    },
  },
}
</script>

<style lang="scss">
.btn-accept {
  color: green;
}

.btn-decline {
  color: red;
}
</style>
