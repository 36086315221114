<template>
  <div class="tickets-items__row">
    <div
      class="tickets-items__item"
      :class="{
        'tickets-items__item--discount':
          ticketCategory.attributes.promo_code !== null,
      }"
    >
      <div class="row">
        <div class="col-md-5 col-lg-6">
          <div class="tickets-items__item--left">
            <h3>{{ ticketCategory.attributes.title }}</h3>
            <div class="tickets-items__item--left_meta">
              <span
                class="tickets-items__item--left_meta__date myomr-calendar"
                >{{ ticketCategory.attributes.period }}</span
              >
              <span v-if="ticketCategory.attributes.location"> in </span>
              <span class="tickets-items__item--left_meta__location">{{
                ticketCategory.attributes.location
              }}</span>
            </div>
            <div class="tickets-items__item--left_description">
              <p>{{ ticketCategory.attributes.description }}</p>
            </div>
          </div>
        </div>

        <div class="col-8 offset-2 col-md-3 offset-md-0 col-lg-2">
          <div class="tickets-items__item--middle">
            <div class="input-group">
              <div class="input-group-prepend">
                <button
                  id="quantity-minus-button"
                  class="btn btn-input-group-icon quantity-minus-button"
                  type="button"
                  @click.prevent="decrement()"
                ></button>
              </div>
              <input
                v-model="quantity"
                v-validate.initial="
                  `max_value:${max}|isMultipleOf:${inMultiplesOf}`
                "
                :max="max"
                class="form-control form-number"
                :class="{ error: errors.has('quantity') }"
                type="number"
                name="quantity"
              />
              <div class="input-group-append">
                <button
                  id="quantity-plus-button"
                  class="btn btn-input-group-icon quantity-plus-button"
                  type="button"
                  @click.prevent="increment()"
                ></button>
              </div>
              <span v-show="errors.has('quantity')" class="is-invalid">{{
                errors.first('quantity')
              }}</span>
            </div>
            <div
              v-if="ticketCategory.attributes.times_usable !== null"
              class="tickets-items__item--middle__maxtickets"
            >
              {{
                $tc(
                  'ticketCategories.ticketsMax',
                  ticketCategory.attributes.times_usable,
                  { count: ticketCategory.attributes.times_usable },
                )
              }}
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="tickets-items__item--right">
            <div
              v-if="showStrikePrice"
              class="tickets-items__item--right_fullprice"
              v-html="
                $t('ticketCategories.priceThin', { price: netOriginalPrice })
              "
            ></div>
            <div
              class="tickets-items__item--right_price"
              :class="{
                'tickets-items__item--right_price-discount': showStrikePrice,
              }"
              v-html="$t('ticketCategories.priceThin', { price: netPrice })"
            ></div>
            <div
              v-if="ticketCategory.attributes.offer_end_time !== null"
              class="tickets-items__item--right_ends"
            >
              {{ $t('ticketCategory.offerEndsAt') }}
              {{ ticketCategory.attributes.offer_end_time }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { segmentEvent } from '../services/segmentTracking'

export default {
  props: {
    ticketCategory: {
      type: Object,
      required: true,
    },
  },
  computed: {
    netOriginalPrice() {
      return this.formatPrice(
        parseFloat(this.ticketCategory.attributes.price) +
          parseFloat(this.ticketCategory.attributes.discount_amount),
      )
    },
    showStrikePrice() {
      return (
        (this.ticketCategory.attributes.promo_code !== null &&
          this.ticketCategory.attributes.discount_amount !== '0.0') ||
        this.applyQuantityDiscount
      )
    },
    netPrice() {
      if (this.applyQuantityDiscount) {
        return this.formatPrice(
          parseFloat(this.ticketCategory.attributes.price) -
            (parseFloat(this.ticketCategory.attributes.price) / 100.0) *
              parseFloat(this.ticketCategory.attributes.bulk_discount),
        )
      } else {
        return this.formatPrice(this.ticketCategory.attributes.price)
      }
    },
    max() {
      if (
        this.ticketCategory.attributes.times_usable === null ||
        this.ticketCategory.attributes.times_usable === undefined
      )
        return 100
      return this.ticketCategory.attributes.times_usable
    },
    applyQuantityDiscount() {
      if (
        this.ticketCategory.attributes.promo_code !== null &&
        this.ticketCategory.attributes.discount_amount !== '0.0'
      ) {
        return false
      }

      if (
        Number.isInteger(
          this.ticketCategory.attributes.bulk_discount_activation_limit,
        )
      ) {
        return (
          this.ticketCategory.attributes.bulk_discount_activation_limit <=
          this.quantity
        )
      }

      return false
    },
    inMultiplesOf() {
      return this.ticketCategory.attributes.in_multiples_of
    },
    quantity: {
      get() {
        if (
          this.$store.state.ticketsByCategory[this.ticketCategory.id] ===
          undefined
        ) {
          return 0
        }
        return this.$store.state.ticketsByCategory[this.ticketCategory.id]
          .length
      },
      set(quantity) {
        this.updateQuantity(quantity)
      },
    },
  },
  watch: {
    'fields.quantity.invalid': {
      immediate: true,
      handler(value) {
        if (value === true) {
          this.$emit('quantityChangeValid', false, this.ticketCategory.id)
        } else if (value === false) {
          this.$emit('quantityChangeValid', true, this.ticketCategory.id)
        }
      },
    },
  },
  methods: {
    increment() {
      if (this.quantity < this.max) {
        this.updateQuantity(this.quantity + this.inMultiplesOf)
        segmentEvent('Add to Cart', {
          new_quantity: this.quantity,
          old_quantity: this.quantity - this.inMultiplesOf,
          ticket_type: this.ticketCategory.id,
          ticket_type: this.ticketCategory.attributes.title,
        })
      }
    },
    decrement() {
      if (this.quantity > 0) {
        this.updateQuantity(this.quantity - this.inMultiplesOf)
        segmentEvent('Remove from Cart', {
          new_quantity: this.quantity,
          old_quantity: this.quantity + this.inMultiplesOf,
          ticket_type: this.ticketCategory.id,
          ticket_type: this.ticketCategory.attributes.title,
        })
      }
    },
    updateQuantity(quantity) {
      const event = quantity > this.quantity ? 'addToCart' : 'removeFromCart'
      const quantityChange = Math.abs(this.quantity - quantity)
      const price = parseFloat(this.ticketCategory.attributes.price)
      const value = quantityChange * price

      if (this.$gtm.enabled()) {
        this.$gtm.trackEvent({
          event,
          ecommerce: {
            value,
            items: [
              {
                item_id: this.ticketCategory.id.replace(
                  `___${this.$store.state.validPromoCode}`,
                  '',
                ),
                item_name: this.ticketCategory.attributes.title,
                price,
                category: `${this.$store.state.headEventSlug} Ticket`,
                quantity: quantityChange,
              },
            ],
          },
        })
      }

      this.$store.commit('setQuantityForCategory', {
        ticketCategoryID: this.ticketCategory.id,
        quantity,
      })
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>
