<template>
  <div
    id="tickets-wrapper"
    :class="{ 'tickets-overview': showTicketsOverviewStyle }"
  >
    <div
      id="app"
      class="tickets-section smooth-section"
      :class="{ loading: loading }"
    >
      <Spinner v-if="loading" />
      <router-view />
      <div v-if="genericError" class="alert alert-danger" role="alert">
        {{ genericError }}
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { mapState } from 'vuex'
import Spinner from './components/Spinner'

export default {
  components: { Spinner },
  data() {
    return {
      initialBodyOverflow: document.body.style.overflow,
    }
  },
  computed: {
    ...mapState(['genericError', 'loading']),
    showTicketsOverviewStyle() {
      return (
        this.$route.name === 'CodeInvalid' ||
        this.$route.name === 'QuotaUsedUp' ||
        this.$route.name === 'QuotaExpired' ||
        this.$route.name === 'TicketsChooser' ||
        this.$route.name === 'TicketPartner' ||
        this.$route.name === 'InviteWrapper'
      )
    },
  },
  watch: {
    loading(newValue) {
      document.body.style.overflow = newValue
        ? 'hidden'
        : this.initialBodyOverflow
    },
  },
  mounted() {
    if (this.$route.name !== 'InviteWrapper') {
      // make sure in ticket_categories no discounted invitee categories are loaded
      this.$store.commit('setInviteeToken', null)
    }
  },
}
</script>

<style lang="scss">
@import './assets/stylesheets/application.scss';

.loading:before {
  content: '';
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  display: block;
  background: rgba(black, 0.7);
}
</style>
