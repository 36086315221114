<template>
  <div :class="`form-group ${wrapperClass}`">
    <select
      :id="idString"
      v-validate="rules"
      :name="attribute"
      :class="{
        required: required(),
        'form-control': true,
        'is-invalid': errors.has(attribute),
      }"
      size="1"
      @change="valueChanged"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :disabled="required() && !option.value"
        :selected="value == option.value"
        :value="option.value"
      >
        {{ option.text }}
      </option>
    </select>
    <span v-show="errors.has(attribute)" class="invalid-feedback">{{
      errors.first(attribute)
    }}</span>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    object: {
      type: String,
      required: true,
    },
    options: {
      required: true,
      type: Array,
    },
    attribute: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    rules: {},
    wrapperClass: {
      type: String,
      default: 'col-md-12',
    },
  },
  computed: {
    idString() {
      return `${this.object}_${this.attribute}`
    },
  },
  methods: {
    valueChanged(e) {
      this.$emit('input', e.target.value)
    },
    required() {
      if (this.rules === undefined) return false
      return this.rules.match(/required/gi) !== null
    },
  },
}
</script>
