<template>
  <div class="tickets-items__row tickets-items__row--small">
    <div class="tickets-items__item tickets-items__item--discount">
      <div class="row">
        <div class="col-6 col-md-7 col-xl-6">
          <div class="tickets-items__item--left">
            <h3>{{ title }}</h3>
            <div class="tickets-items__item--left_meta">
              <span class="tickets-items__item--left_meta__date myomr-calendar">
                {{ period }}
              </span>
              in
              <span class="tickets-items__item--left_meta__location">
                {{ location }}
              </span>
            </div>
          </div>
        </div>

        <div class="col-6 col-md-5 col-xl-6">
          <div class="tickets-items__item--right">
            <div
              v-if="ticketsPerCategory"
              class="tickets-items__item--right_maximum"
            >
              {{
                $tc('ticketCategories.ticketsMax', ticketsPerCategory, {
                  count: ticketsPerCategory,
                })
              }}
            </div>
            <div
              v-if="discount !== 0"
              class="tickets-items__item--right_fullprice"
              v-html="$t('ticketCategories.price', { price: originalNetPrice })"
            ></div>
            <div
              class="tickets-items__item--right_price tickets-items__item--right_price-discount"
              v-html="$t('ticketCategories.price', { price: discountNetPrice })"
            ></div>
            <div v-if="offerEndTime" class="tickets-items__item--right_ends">
              {{ `${$t('ticketCategories.offerEndsAt')} ${offerEndTime}` }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { required: true, type: String },
    description: { required: true, type: String },
    period: { required: true, type: String },
    location: { required: true, type: String },
    discount: { required: true, type: Number },
    discountAmount: { required: true, type: Number },
    offerEndTime: { type: String },
    price: { type: Number, required: true },
    ticketsPerCategory: { type: Number },
  },
  computed: {
    originalNetPrice() {
      if (this.discountAmount !== 0) {
        return this.formatPrice(this.price + this.discountAmount)
      } else {
        return this.formatPrice(this.price)
      }
    },
    discountNetPrice() {
      return this.formatPrice(this.price)
    },
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>
