import { getCookieValue } from '../helpers/cookies'

export function segmentPageView(source, path) {
  // console.log('PAGE VIEW')
  try {
    let myOmrUser = ''
    if (getCookieValue('myomr.auth')) {
      myOmrUser = JSON.parse(
        window.atob(getCookieValue('myomr.auth').split('.')[1]),
      )
    }
    if (myOmrUser.sub && typeof analytics !== 'undefined') {
      segmentIdentify(myOmrUser)
      analytics.page(source, path)
    }
  } catch (error) {
    console.error(error)
  }
}

export function segmentIdentify(myOmrUser) {
  // console.log('IDENTIFY', myOmrUser)
  analytics.identify(myOmrUser.sub, {
    name: myOmrUser.name,
  })
}

export function segmentEvent(eventName, properties) {
  // console.log('EVENT: ', eventName, properties)
  try {
    let myOmrUser = ''
    if (getCookieValue('myomr.auth')) {
      myOmrUser = JSON.parse(
        window.atob(getCookieValue('myomr.auth').split('.')[1]),
      )
    }
    if (myOmrUser.sub && typeof analytics !== 'undefined') {
      segmentIdentify(myOmrUser)
      analytics.track(eventName, properties)
    }
  } catch (error) {
    console.error(error)
  }
}
