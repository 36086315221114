const countries = [
  {
    code: 'TJ',
    translations: {
      en: 'Tajikistan',
      de: 'Tadschikistan',
    },
  },
  {
    code: 'JM',
    translations: {
      en: 'Jamaica',
      de: 'Jamaika',
    },
  },
  {
    code: 'HT',
    translations: {
      en: 'Haiti',
      de: 'Haiti',
    },
  },
  {
    code: 'ST',
    translations: {
      en: 'Sao Tome and Principe',
      de: 'São Tomé und Príncipe',
    },
  },
  {
    code: 'MS',
    translations: {
      en: 'Montserrat',
      de: 'Montserrat',
    },
  },
  {
    code: 'AE',
    translations: {
      en: 'United Arab Emirates',
      de: 'Vereinigte Arabische Emirate',
    },
  },
  {
    code: 'PK',
    translations: {
      en: 'Pakistan',
      de: 'Pakistan',
    },
  },
  {
    code: 'NL',
    translations: {
      en: 'Netherlands',
      de: 'Niederlande',
    },
  },
  {
    code: 'LU',
    translations: {
      en: 'Luxembourg',
      de: 'Luxemburg',
    },
  },
  {
    code: 'BZ',
    translations: {
      en: 'Belize',
      de: 'Belize',
    },
  },
  {
    code: 'IR',
    translations: {
      en: 'Iran, Islamic Republic of',
      de: 'Iran, Islamische Republik',
    },
  },
  {
    code: 'BO',
    translations: {
      en: 'Bolivia, Plurinational State of',
      de: 'Bolivien, Plurinationaler Staat',
    },
  },
  {
    code: 'UY',
    translations: {
      en: 'Uruguay',
      de: 'Uruguay',
    },
  },
  {
    code: 'GH',
    translations: {
      en: 'Ghana',
      de: 'Ghana',
    },
  },
  {
    code: 'SA',
    translations: {
      en: 'Saudi Arabia',
      de: 'Saudi-Arabien',
    },
  },
  {
    code: 'CI',
    translations: {
      en: "Côte d'Ivoire",
      de: "Côte d'Ivoire",
    },
  },
  {
    code: 'MF',
    translations: {
      en: 'Saint Martin (French part)',
      de: 'Saint Martin (Französischer Teil)',
    },
  },
  {
    code: 'TF',
    translations: {
      en: 'French Southern Territories',
      de: 'Französische Süd- und Antarktisgebiete',
    },
  },
  {
    code: 'AI',
    translations: {
      en: 'Anguilla',
      de: 'Anguilla',
    },
  },
  {
    code: 'QA',
    translations: {
      en: 'Qatar',
      de: 'Katar',
    },
  },
  {
    code: 'SX',
    translations: {
      en: 'Sint Maarten (Dutch part)',
      de: 'Saint-Martin (Niederländischer Teil)',
    },
  },
  {
    code: 'LY',
    translations: {
      en: 'Libya',
      de: 'Libyen',
    },
  },
  {
    code: 'BV',
    translations: {
      en: 'Bouvet Island',
      de: 'Bouvet-Insel',
    },
  },
  {
    code: 'PG',
    translations: {
      en: 'Papua New Guinea',
      de: 'Papua-Neuguinea',
    },
  },
  {
    code: 'KG',
    translations: {
      en: 'Kyrgyzstan',
      de: 'Kirgisistan',
    },
  },
  {
    code: 'GQ',
    translations: {
      en: 'Equatorial Guinea',
      de: 'Äquatorialguinea',
    },
  },
  {
    code: 'EH',
    translations: {
      en: 'Western Sahara',
      de: 'Westsahara',
    },
  },
  {
    code: 'NU',
    translations: {
      en: 'Niue',
      de: 'Niue',
    },
  },
  {
    code: 'PR',
    translations: {
      en: 'Puerto Rico',
      de: 'Puerto Rico',
    },
  },
  {
    code: 'GD',
    translations: {
      en: 'Grenada',
      de: 'Grenada',
    },
  },
  {
    code: 'KR',
    translations: {
      en: 'Korea, Republic of',
      de: 'Korea, Republik',
    },
  },
  {
    code: 'HM',
    translations: {
      en: 'Heard Island and McDonald Islands',
      de: 'Heard und McDonaldinseln',
    },
  },
  {
    code: 'SM',
    translations: {
      en: 'San Marino',
      de: 'San Marino',
    },
  },
  {
    code: 'SL',
    translations: {
      en: 'Sierra Leone',
      de: 'Sierra Leone',
    },
  },
  {
    code: 'CD',
    translations: {
      en: 'Congo, The Democratic Republic of the',
      de: 'Demokratische Republik Kongo',
    },
  },
  {
    code: 'MK',
    translations: {
      en: 'Macedonia, Republic of',
      de: 'Mazedonien, ehemalige jugoslawische Republik',
    },
  },
  {
    code: 'TR',
    translations: {
      en: 'Turkey',
      de: 'Türkei',
    },
  },
  {
    code: 'DZ',
    translations: {
      en: 'Algeria',
      de: 'Algerien',
    },
  },
  {
    code: 'GE',
    translations: {
      en: 'Georgia',
      de: 'Georgien',
    },
  },
  {
    code: 'PS',
    translations: {
      en: 'Palestine, State of',
      de: 'Palästina, Staat',
    },
  },
  {
    code: 'BB',
    translations: {
      en: 'Barbados',
      de: 'Barbados',
    },
  },
  {
    code: 'UA',
    translations: {
      en: 'Ukraine',
      de: 'Ukraine',
    },
  },
  {
    code: 'GP',
    translations: {
      en: 'Guadeloupe',
      de: 'Guadeloupe',
    },
  },
  {
    code: 'PF',
    translations: {
      en: 'French Polynesia',
      de: 'Französisch-Polynesien',
    },
  },
  {
    code: 'NA',
    translations: {
      en: 'Namibia',
      de: 'Namibia',
    },
  },
  {
    code: 'BW',
    translations: {
      en: 'Botswana',
      de: 'Botsuana',
    },
  },
  {
    code: 'SY',
    translations: {
      en: 'Syrian Arab Republic',
      de: 'Syrien, Arabische Republik',
    },
  },
  {
    code: 'TG',
    translations: {
      en: 'Togo',
      de: 'Togo',
    },
  },
  {
    code: 'DO',
    translations: {
      en: 'Dominican Republic',
      de: 'Dominikanische Republik',
    },
  },
  {
    code: 'AQ',
    translations: {
      en: 'Antarctica',
      de: 'Antarktis',
    },
  },
  {
    code: 'CH',
    translations: {
      en: 'Switzerland',
      de: 'Schweiz',
    },
  },
  {
    code: 'MG',
    translations: {
      en: 'Madagascar',
      de: 'Madagaskar',
    },
  },
  {
    code: 'FO',
    translations: {
      en: 'Faroe Islands',
      de: 'Färöer-Inseln',
    },
  },
  {
    code: 'VG',
    translations: {
      en: 'Virgin Islands, British',
      de: 'Britische Jungferninseln',
    },
  },
  {
    code: 'GI',
    translations: {
      en: 'Gibraltar',
      de: 'Gibraltar',
    },
  },
  {
    code: 'BN',
    translations: {
      en: 'Brunei Darussalam',
      de: 'Brunei Darussalam',
    },
  },
  {
    code: 'LA',
    translations: {
      en: "Lao People's Democratic Republic",
      de: 'Laos, Demokratische Volksrepublik',
    },
  },
  {
    code: 'IS',
    translations: {
      en: 'Iceland',
      de: 'Island',
    },
  },
  {
    code: 'EE',
    translations: {
      en: 'Estonia',
      de: 'Estland',
    },
  },
  {
    code: 'UM',
    translations: {
      en: 'United States Minor Outlying Islands',
      de: 'United States Minor Outlying Islands',
    },
  },
  {
    code: 'LT',
    translations: {
      en: 'Lithuania',
      de: 'Litauen',
    },
  },
  {
    code: 'RS',
    translations: {
      en: 'Serbia',
      de: 'Serbien',
    },
  },
  {
    code: 'MR',
    translations: {
      en: 'Mauritania',
      de: 'Mauretanien',
    },
  },
  {
    code: 'AD',
    translations: {
      en: 'Andorra',
      de: 'Andorra',
    },
  },
  {
    code: 'HU',
    translations: {
      en: 'Hungary',
      de: 'Ungarn',
    },
  },
  {
    code: 'TK',
    translations: {
      en: 'Tokelau',
      de: 'Tokelau',
    },
  },
  {
    code: 'MY',
    translations: {
      en: 'Malaysia',
      de: 'Malaysia',
    },
  },
  {
    code: 'AO',
    translations: {
      en: 'Angola',
      de: 'Angola',
    },
  },
  {
    code: 'CV',
    translations: {
      en: 'Cape Verde',
      de: 'Cabo Verde',
    },
  },
  {
    code: 'NF',
    translations: {
      en: 'Norfolk Island',
      de: 'Norfolkinsel',
    },
  },
  {
    code: 'PA',
    translations: {
      en: 'Panama',
      de: 'Panama',
    },
  },
  {
    code: 'GW',
    translations: {
      en: 'Guinea-Bissau',
      de: 'Guinea-Bissau',
    },
  },
  {
    code: 'BE',
    translations: {
      en: 'Belgium',
      de: 'Belgien',
    },
  },
  {
    code: 'PT',
    translations: {
      en: 'Portugal',
      de: 'Portugal',
    },
  },
  {
    code: 'GB',
    translations: {
      en: 'United Kingdom',
      de: 'Vereinigtes Königreich',
    },
  },
  {
    code: 'IM',
    translations: {
      en: 'Isle of Man',
      de: 'Insel Man',
    },
  },
  {
    code: 'US',
    translations: {
      en: 'United States',
      de: 'Vereinigte Staaten',
    },
  },
  {
    code: 'YE',
    translations: {
      en: 'Yemen',
      de: 'Jemen',
    },
  },
  {
    code: 'HK',
    translations: {
      en: 'Hong Kong',
      de: 'Hongkong',
    },
  },
  {
    code: 'AZ',
    translations: {
      en: 'Azerbaijan',
      de: 'Aserbaidschan',
    },
  },
  {
    code: 'CC',
    translations: {
      en: 'Cocos (Keeling) Islands',
      de: 'Kokos-(Keeling-)Inseln',
    },
  },
  {
    code: 'ML',
    translations: {
      en: 'Mali',
      de: 'Mali',
    },
  },
  {
    code: 'SK',
    translations: {
      en: 'Slovakia',
      de: 'Slowakei',
    },
  },
  {
    code: 'VU',
    translations: {
      en: 'Vanuatu',
      de: 'Vanuatu',
    },
  },
  {
    code: 'TL',
    translations: {
      en: 'Timor-Leste',
      de: 'Timor-Leste',
    },
  },
  {
    code: 'HR',
    translations: {
      en: 'Croatia',
      de: 'Kroatien',
    },
  },
  {
    code: 'SR',
    translations: {
      en: 'Suriname',
      de: 'Suriname',
    },
  },
  {
    code: 'MU',
    translations: {
      en: 'Mauritius',
      de: 'Mauritius',
    },
  },
  {
    code: 'CZ',
    translations: {
      en: 'Czech Republic',
      de: 'Tschechische Republik',
    },
  },
  {
    code: 'PM',
    translations: {
      en: 'Saint Pierre and Miquelon',
      de: 'St. Pierre und Miquelon',
    },
  },
  {
    code: 'LS',
    translations: {
      en: 'Lesotho',
      de: 'Lesotho',
    },
  },
  {
    code: 'WS',
    translations: {
      en: 'Samoa',
      de: 'Samoa',
    },
  },
  {
    code: 'KM',
    translations: {
      en: 'Comoros',
      de: 'Komoren',
    },
  },
  {
    code: 'IT',
    translations: {
      en: 'Italy',
      de: 'Italien',
    },
  },
  {
    code: 'BI',
    translations: {
      en: 'Burundi',
      de: 'Burundi',
    },
  },
  {
    code: 'WF',
    translations: {
      en: 'Wallis and Futuna',
      de: 'Wallis und Futuna',
    },
  },
  {
    code: 'GN',
    translations: {
      en: 'Guinea',
      de: 'Guinea',
    },
  },
  {
    code: 'SG',
    translations: {
      en: 'Singapore',
      de: 'Singapur',
    },
  },
  {
    code: 'CO',
    translations: {
      en: 'Colombia',
      de: 'Kolumbien',
    },
  },
  {
    code: 'CN',
    translations: {
      en: 'China',
      de: 'China',
    },
  },
  {
    code: 'AW',
    translations: {
      en: 'Aruba',
      de: 'Aruba',
    },
  },
  {
    code: 'MA',
    translations: {
      en: 'Morocco',
      de: 'Marokko',
    },
  },
  {
    code: 'FI',
    translations: {
      en: 'Finland',
      de: 'Finnland',
    },
  },
  {
    code: 'VA',
    translations: {
      en: 'Holy See (Vatican City State)',
      de: 'Heiliger Stuhl (Staat Vatikanstadt)',
    },
  },
  {
    code: 'ZW',
    translations: {
      en: 'Zimbabwe',
      de: 'Simbabwe',
    },
  },
  {
    code: 'KY',
    translations: {
      en: 'Cayman Islands',
      de: 'Cayman-Inseln',
    },
  },
  {
    code: 'BH',
    translations: {
      en: 'Bahrain',
      de: 'Bahrain',
    },
  },
  {
    code: 'PY',
    translations: {
      en: 'Paraguay',
      de: 'Paraguay',
    },
  },
  {
    code: 'EC',
    translations: {
      en: 'Ecuador',
      de: 'Ecuador',
    },
  },
  {
    code: 'LR',
    translations: {
      en: 'Liberia',
      de: 'Liberia',
    },
  },
  {
    code: 'RU',
    translations: {
      en: 'Russian Federation',
      de: 'Russische Föderation',
    },
  },
  {
    code: 'PL',
    translations: {
      en: 'Poland',
      de: 'Polen',
    },
  },
  {
    code: 'OM',
    translations: {
      en: 'Oman',
      de: 'Oman',
    },
  },
  {
    code: 'MT',
    translations: {
      en: 'Malta',
      de: 'Malta',
    },
  },
  {
    code: 'SS',
    translations: {
      en: 'South Sudan',
      de: 'Südsudan',
    },
  },
  {
    code: 'DE',
    translations: {
      en: 'Germany',
      de: 'Deutschland',
    },
  },
  {
    code: 'TM',
    translations: {
      en: 'Turkmenistan',
      de: 'Turkmenistan',
    },
  },
  {
    code: 'SJ',
    translations: {
      en: 'Svalbard and Jan Mayen',
      de: 'Svalbard und Jan Mayen',
    },
  },
  {
    code: 'MM',
    translations: {
      en: 'Myanmar',
      de: 'Myanmar',
    },
  },
  {
    code: 'TT',
    translations: {
      en: 'Trinidad and Tobago',
      de: 'Trinidad und Tobago',
    },
  },
  {
    code: 'IL',
    translations: {
      en: 'Israel',
      de: 'Israel',
    },
  },
  {
    code: 'BD',
    translations: {
      en: 'Bangladesh',
      de: 'Bangladesch',
    },
  },
  {
    code: 'NR',
    translations: {
      en: 'Nauru',
      de: 'Nauru',
    },
  },
  {
    code: 'LK',
    translations: {
      en: 'Sri Lanka',
      de: 'Sri Lanka',
    },
  },
  {
    code: 'UG',
    translations: {
      en: 'Uganda',
      de: 'Uganda',
    },
  },
  {
    code: 'NG',
    translations: {
      en: 'Nigeria',
      de: 'Nigeria',
    },
  },
  {
    code: 'BQ',
    translations: {
      en: 'Bonaire, Sint Eustatius and Saba',
      de: 'Bonaire, Sint Eustatius und Saba',
    },
  },
  {
    code: 'MX',
    translations: {
      en: 'Mexico',
      de: 'Mexiko',
    },
  },
  {
    code: 'CW',
    translations: {
      en: 'Curaçao',
      de: 'Curaçao',
    },
  },
  {
    code: 'SI',
    translations: {
      en: 'Slovenia',
      de: 'Slowenien',
    },
  },
  {
    code: 'MN',
    translations: {
      en: 'Mongolia',
      de: 'Mongolei',
    },
  },
  {
    code: 'CA',
    translations: {
      en: 'Canada',
      de: 'Kanada',
    },
  },
  {
    code: 'AX',
    translations: {
      en: 'Åland Islands',
      de: 'Åland-Inseln',
    },
  },
  {
    code: 'VN',
    translations: {
      en: 'Viet Nam',
      de: 'Vietnam',
    },
  },
  {
    code: 'TW',
    translations: {
      en: 'Taiwan',
      de: 'Taiwan',
    },
  },
  {
    code: 'JP',
    translations: {
      en: 'Japan',
      de: 'Japan',
    },
  },
  {
    code: 'IO',
    translations: {
      en: 'British Indian Ocean Territory',
      de: 'Britisches Territorium im Indischen Ozean',
    },
  },
  {
    code: 'RO',
    translations: {
      en: 'Romania',
      de: 'Rumänien',
    },
  },
  {
    code: 'BG',
    translations: {
      en: 'Bulgaria',
      de: 'Bulgarien',
    },
  },
  {
    code: 'GU',
    translations: {
      en: 'Guam',
      de: 'Guam',
    },
  },
  {
    code: 'BR',
    translations: {
      en: 'Brazil',
      de: 'Brasilien',
    },
  },
  {
    code: 'AM',
    translations: { en: 'Armenia', de: 'Armenien' },
  },
  { code: 'ZM', translations: { en: 'Zambia', de: 'Sambia' } },
  { code: 'DJ', translations: { en: 'Djibouti', de: 'Dschibuti' } },
  { code: 'JE', translations: { en: 'Jersey', de: 'Jersey' } },
  { code: 'AT', translations: { en: 'Austria', de: 'Österreich' } },
  { code: 'CM', translations: { en: 'Cameroon', de: 'Kamerun' } },
  { code: 'SE', translations: { en: 'Sweden', de: 'Schweden' } },
  { code: 'FJ', translations: { en: 'Fiji', de: 'Fidschi' } },
  { code: 'KZ', translations: { en: 'Kazakhstan', de: 'Kasachstan' } },
  { code: 'GL', translations: { en: 'Greenland', de: 'Grönland' } },
  { code: 'GY', translations: { en: 'Guyana', de: 'Guyana' } },
  {
    code: 'CX',
    translations: { en: 'Christmas Island', de: 'Weihnachtsinseln' },
  },
  { code: 'MW', translations: { en: 'Malawi', de: 'Malawi' } },
  { code: 'TN', translations: { en: 'Tunisia', de: 'Tunesien' } },
  { code: 'ZA', translations: { en: 'South Africa', de: 'Südafrika' } },
  { code: 'TO', translations: { en: 'Tonga', de: 'Tonga' } },
  { code: 'CY', translations: { en: 'Cyprus', de: 'Zypern' } },
  { code: 'MV', translations: { en: 'Maldives', de: 'Malediven' } },
  { code: 'PN', translations: { en: 'Pitcairn', de: 'Pitcairn' } },
  { code: 'RW', translations: { en: 'Rwanda', de: 'Ruanda' } },
  { code: 'NI', translations: { en: 'Nicaragua', de: 'Nicaragua' } },
  {
    code: 'KN',
    translations: { en: 'Saint Kitts and Nevis', de: 'St. Kitts und Nevis' },
  },
  { code: 'BJ', translations: { en: 'Benin', de: 'Benin' } },
  { code: 'ET', translations: { en: 'Ethiopia', de: 'Äthiopien' } },
  { code: 'GM', translations: { en: 'Gambia', de: 'Gambia' } },
  {
    code: 'TZ',
    translations: {
      en: 'Tanzania, United Republic of',
      de: 'Tansania, Vereinigte Republik',
    },
  },
  {
    code: 'VC',
    translations: {
      en: 'Saint Vincent and the Grenadines',
      de: 'St. Vincent und die Grenadinen',
    },
  },
  {
    code: 'FK',
    translations: {
      en: 'Falkland Islands (Malvinas)',
      de: 'Falklandinseln (Malwinen)',
    },
  },
  { code: 'SD', translations: { en: 'Sudan', de: 'Sudan' } },
  { code: 'MC', translations: { en: 'Monaco', de: 'Monaco' } },
  { code: 'AU', translations: { en: 'Australia', de: 'Australien' } },
  { code: 'CL', translations: { en: 'Chile', de: 'Chile' } },
  { code: 'DK', translations: { en: 'Denmark', de: 'Dänemark' } },
  { code: 'FR', translations: { en: 'France', de: 'Frankreich' } },
  {
    code: 'TC',
    translations: {
      en: 'Turks and Caicos Islands',
      de: 'Turks- und Caicosinseln',
    },
  },
  { code: 'CU', translations: { en: 'Cuba', de: 'Kuba' } },
  { code: 'AL', translations: { en: 'Albania', de: 'Albanien' } },
  { code: 'MZ', translations: { en: 'Mozambique', de: 'Mosambik' } },
  { code: 'BS', translations: { en: 'Bahamas', de: 'Bahamas' } },
  { code: 'NE', translations: { en: 'Niger', de: 'Niger' } },
  { code: 'GT', translations: { en: 'Guatemala', de: 'Guatemala' } },
  { code: 'LI', translations: { en: 'Liechtenstein', de: 'Liechtenstein' } },
  { code: 'NP', translations: { en: 'Nepal', de: 'Nepal' } },
  { code: 'BF', translations: { en: 'Burkina Faso', de: 'Burkina Faso' } },
  { code: 'PW', translations: { en: 'Palau', de: 'Palau' } },
  { code: 'KW', translations: { en: 'Kuwait', de: 'Kuwait' } },
  { code: 'IN', translations: { en: 'India', de: 'Indien' } },
  { code: 'GA', translations: { en: 'Gabon', de: 'Gabun' } },
  { code: 'TV', translations: { en: 'Tuvalu', de: 'Tuvalu' } },
  { code: 'MO', translations: { en: 'Macao', de: 'Macao' } },
  {
    code: 'SH',
    translations: {
      en: 'Saint Helena, Ascension and Tristan da Cunha',
      de: 'St. Helena, Ascension und Tristan da Cunha',
    },
  },
  {
    code: 'MD',
    translations: { en: 'Moldova, Republic of', de: 'Moldau, Republik' },
  },
  { code: 'CK', translations: { en: 'Cook Islands', de: 'Cookinseln' } },
  { code: 'AR', translations: { en: 'Argentina', de: 'Argentinien' } },
  { code: 'SC', translations: { en: 'Seychelles', de: 'Seychellen' } },
  { code: 'IE', translations: { en: 'Ireland', de: 'Irland' } },
  { code: 'ES', translations: { en: 'Spain', de: 'Spanien' } },
  { code: 'LB', translations: { en: 'Lebanon', de: 'Libanon' } },
  { code: 'BM', translations: { en: 'Bermuda', de: 'Bermuda' } },
  { code: 'RE', translations: { en: 'Réunion', de: 'Réunion' } },
  { code: 'KI', translations: { en: 'Kiribati', de: 'Kiribati' } },
  {
    code: 'AG',
    translations: { en: 'Antigua and Barbuda', de: 'Antigua und Barbuda' },
  },
  { code: 'MQ', translations: { en: 'Martinique', de: 'Martinique' } },
  { code: 'SV', translations: { en: 'El Salvador', de: 'El Salvador' } },
  { code: 'JO', translations: { en: 'Jordan', de: 'Jordanien' } },
  { code: 'TH', translations: { en: 'Thailand', de: 'Thailand' } },
  { code: 'SO', translations: { en: 'Somalia', de: 'Somalia' } },
  {
    code: 'MH',
    translations: { en: 'Marshall Islands', de: 'Marshallinseln' },
  },
  { code: 'CG', translations: { en: 'Congo', de: 'Kongo' } },
  {
    code: 'KP',
    translations: {
      en: "Korea, Democratic People's Republic of",
      de: 'Korea, Demokratische Volksrepublik',
    },
  },
  {
    code: 'GF',
    translations: { en: 'French Guiana', de: 'Französisch-Guyana' },
  },
  {
    code: 'BA',
    translations: {
      en: 'Bosnia and Herzegovina',
      de: 'Bosnien und Herzegowina',
    },
  },
  { code: 'YT', translations: { en: 'Mayotte', de: 'Mayotte' } },
  {
    code: 'GS',
    translations: {
      en: 'South Georgia and the South Sandwich Islands',
      de: 'South Georgia und die Südlichen Sandwichinseln',
    },
  },
  { code: 'KE', translations: { en: 'Kenya', de: 'Kenia' } },
  { code: 'PE', translations: { en: 'Peru', de: 'Peru' } },
  { code: 'BT', translations: { en: 'Bhutan', de: 'Bhutan' } },
  { code: 'SZ', translations: { en: 'Swaziland', de: 'Swasiland' } },
  { code: 'CR', translations: { en: 'Costa Rica', de: 'Costa Rica' } },
  { code: 'TD', translations: { en: 'Chad', de: 'Tschad' } },
  { code: 'DM', translations: { en: 'Dominica', de: 'Dominica' } },
  { code: 'NC', translations: { en: 'New Caledonia', de: 'Neukaledonien' } },
  { code: 'GR', translations: { en: 'Greece', de: 'Griechenland' } },
  { code: 'GG', translations: { en: 'Guernsey', de: 'Guernsey' } },
  { code: 'HN', translations: { en: 'Honduras', de: 'Honduras' } },
  {
    code: 'VI',
    translations: {
      en: 'Virgin Islands, U.S.',
      de: 'Amerikanische Jungferninseln',
    },
  },
  {
    code: 'CF',
    translations: {
      en: 'Central African Republic',
      de: 'Zentralafrikanische Republik',
    },
  },
  { code: 'SN', translations: { en: 'Senegal', de: 'Senegal' } },
  { code: 'AF', translations: { en: 'Afghanistan', de: 'Afghanistan' } },
  {
    code: 'MP',
    translations: {
      en: 'Northern Mariana Islands',
      de: 'Nördliche Mariana-Inseln',
    },
  },
  { code: 'PH', translations: { en: 'Philippines', de: 'Philippinen' } },
  { code: 'BY', translations: { en: 'Belarus', de: 'Weißrussland' } },
  { code: 'LV', translations: { en: 'Latvia', de: 'Lettland' } },
  { code: 'NO', translations: { en: 'Norway', de: 'Norwegen' } },
  { code: 'EG', translations: { en: 'Egypt', de: 'Ägypten' } },
  { code: 'KH', translations: { en: 'Cambodia', de: 'Kambodscha' } },
  { code: 'IQ', translations: { en: 'Iraq', de: 'Irak' } },
  { code: 'LC', translations: { en: 'Saint Lucia', de: 'St. Lucia' } },
  { code: 'NZ', translations: { en: 'New Zealand', de: 'Neuseeland' } },
  {
    code: 'BL',
    translations: { en: 'Saint Barthélemy', de: 'Saint-Barthélemy' },
  },
  { code: 'UZ', translations: { en: 'Uzbekistan', de: 'Usbekistan' } },
  { code: 'ID', translations: { en: 'Indonesia', de: 'Indonesien' } },
  { code: 'ER', translations: { en: 'Eritrea', de: 'Eritrea' } },
  {
    code: 'VE',
    translations: {
      en: 'Venezuela, Bolivarian Republic of',
      de: 'Venezuela, Bolivarische Republik',
    },
  },
  {
    code: 'FM',
    translations: {
      en: 'Micronesia, Federated States of',
      de: 'Mikronesien, Föderierte Staaten von',
    },
  },
  { code: 'SB', translations: { en: 'Solomon Islands', de: 'Salomoninseln' } },
  { code: 'ME', translations: { en: 'Montenegro', de: 'Montenegro' } },
  {
    code: 'AS',
    translations: { en: 'American Samoa', de: 'Amerikanisch-Samoa' },
  },
]

const sortedCountries = countries.sort((countryA, countryB) => {
  if (countryA.translations.de < countryB.translations.de) {
    return -1
  }

  if (countryA.translations.de > countryB.translations.de) {
    return 1
  }

  return 0
})

const array_move = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
}

const deIndex = sortedCountries.findIndex((country) => country.code === 'DE')
array_move(sortedCountries, deIndex, 0)

const atIndex = sortedCountries.findIndex((country) => country.code === 'AT')
array_move(sortedCountries, atIndex, 1)

const chIndex = sortedCountries.findIndex((country) => country.code === 'CH')
array_move(sortedCountries, chIndex, 2)

const countryCodes = countries.map((x) => x.code)

const deCountries = sortedCountries.reduce(function (obj, country) {
  obj[country.code] = country.translations.de
  return obj
}, {})

const enCountries = sortedCountries.reduce(function (obj, country) {
  obj[country.code] = country.translations.en
  return obj
}, {})

export { countryCodes, deCountries, enCountries }
