var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tickets-items__row"},[_c('div',{staticClass:"tickets-items__item",class:{
      'tickets-items__item--discount':
        _vm.ticketCategory.attributes.promo_code !== null,
    }},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5 col-lg-6"},[_c('div',{staticClass:"tickets-items__item--left"},[_c('h3',[_vm._v(_vm._s(_vm.ticketCategory.attributes.title))]),_c('div',{staticClass:"tickets-items__item--left_meta"},[_c('span',{staticClass:"tickets-items__item--left_meta__date myomr-calendar"},[_vm._v(_vm._s(_vm.ticketCategory.attributes.period))]),(_vm.ticketCategory.attributes.location)?_c('span',[_vm._v(" in ")]):_vm._e(),_c('span',{staticClass:"tickets-items__item--left_meta__location"},[_vm._v(_vm._s(_vm.ticketCategory.attributes.location))])]),_c('div',{staticClass:"tickets-items__item--left_description"},[_c('p',[_vm._v(_vm._s(_vm.ticketCategory.attributes.description))])])])]),_c('div',{staticClass:"col-8 offset-2 col-md-3 offset-md-0 col-lg-2"},[_c('div',{staticClass:"tickets-items__item--middle"},[_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn btn-input-group-icon quantity-minus-button",attrs:{"id":"quantity-minus-button","type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.decrement()}}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.quantity),expression:"quantity"},{name:"validate",rawName:"v-validate.initial",value:(
                ("max_value:" + _vm.max + "|isMultipleOf:" + _vm.inMultiplesOf)
              ),expression:"\n                `max_value:${max}|isMultipleOf:${inMultiplesOf}`\n              ",modifiers:{"initial":true}}],staticClass:"form-control form-number",class:{ error: _vm.errors.has('quantity') },attrs:{"max":_vm.max,"type":"number","name":"quantity"},domProps:{"value":(_vm.quantity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.quantity=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-input-group-icon quantity-plus-button",attrs:{"id":"quantity-plus-button","type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.increment()}}})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('quantity')),expression:"errors.has('quantity')"}],staticClass:"is-invalid"},[_vm._v(_vm._s(_vm.errors.first('quantity')))])]),(_vm.ticketCategory.attributes.times_usable !== null)?_c('div',{staticClass:"tickets-items__item--middle__maxtickets"},[_vm._v(" "+_vm._s(_vm.$tc( 'ticketCategories.ticketsMax', _vm.ticketCategory.attributes.times_usable, { count: _vm.ticketCategory.attributes.times_usable } ))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"tickets-items__item--right"},[(_vm.showStrikePrice)?_c('div',{staticClass:"tickets-items__item--right_fullprice",domProps:{"innerHTML":_vm._s(
              _vm.$t('ticketCategories.priceThin', { price: _vm.netOriginalPrice })
            )}}):_vm._e(),_c('div',{staticClass:"tickets-items__item--right_price",class:{
              'tickets-items__item--right_price-discount': _vm.showStrikePrice,
            },domProps:{"innerHTML":_vm._s(_vm.$t('ticketCategories.priceThin', { price: _vm.netPrice }))}}),(_vm.ticketCategory.attributes.offer_end_time !== null)?_c('div',{staticClass:"tickets-items__item--right_ends"},[_vm._v(" "+_vm._s(_vm.$t('ticketCategory.offerEndsAt'))+" "+_vm._s(_vm.ticketCategory.attributes.offer_end_time)+" ")]):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }