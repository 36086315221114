<template>
  <div>
    <section id="section-ticket-partner" class="section-ticket-partner">
      <div class="section-ticket-partner__inner">
        <div class="container">
          <div class="row">
            <div class="toasted toasted-primary error">
              <i18n
                path="quotaUsedUp.error"
                tag="span"
                for="codeInvalid.linkToShopLabel"
              >
                <router-link :to="`/${locale}/omr24`">{{
                  $t('codeInvalid.linkToShopLabel')
                }}</router-link>
              </i18n>
            </div>
            <div
              class="col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2"
            >
              <div class="ticket-partner__content">
                <h1 class="ticket-partner__content--name">
                  {{ $t('codeInvalid.title') }}
                </h1>

                <h2 class="ticket-partner__content--title">
                  {{ $t('codeInvalid.headline') }}
                </h2>

                <div class="ticket-partner__content--description">
                  <p>{{ $t('codeInvalid.description') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="js">
export default {
  data() {
    return {
      locale: this.$i18n.locale,
    }
  },
  mounted() {
    this.$store.commit('setLoading', false)
  },
}
</script>

<style lang="scss" scoped>
.error {
  max-width: 960px;
  margin: 0 auto;
  margin-bottom: 40px;
}
</style>
