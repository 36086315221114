const titles = (i18n) => {
  return [
    { text: i18n.t('fields.title'), value: '' },
    { text: i18n.t('collections.title.dr'), value: 'dr' },
    { text: i18n.t('collections.title.prof'), value: 'prof' },
    { text: i18n.t('collections.title.prof_dr'), value: 'prof_dr' },
    { text: i18n.t('collections.title.ing'), value: 'ing' },
    { text: i18n.t('collections.title.dipl_ing'), value: 'dipl_ing' },
    { text: i18n.t('collections.title.mag'), value: 'mag' },
  ]
}

const ticketOwnerships = (i18n) => {
  return [
    { value: '', text: i18n.t('collections.ticketOwnership.none') },
    { value: 'me', text: i18n.t('collections.ticketOwnership.me') },
    { value: 'other', text: i18n.t('collections.ticketOwnership.other') },
  ]
}

const salutations = (i18n) => {
  return [
    { value: '', text: i18n.t('fields.salutation') },
    { value: 'male', text: i18n.t('fields.male') },
    { value: 'female', text: i18n.t('fields.female') },
    { value: 'other', text: i18n.t('fields.other') },
  ]
}

export { titles, salutations, ticketOwnerships }
