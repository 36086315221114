<template>
  <div>
    <div class="navbar-top">
      <img src="@/assets/images/logo-black.svg" class="logo" alt="OMR" />
    </div>
    <StepIndicator />
    <div class="tickets">
      <div class="container">
        <div class="row">
          <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div class="tickets-checkout">
              <div class="tickets-box tickets-checkout__intro">
                <div class="tickets-box__inner tickets-box__white">
                  <h2 class="h3">{{ $t('tickets.header') }}</h2>
                  <ul>
                    <li>{{ $t('tickets.bullet_one') }}</li>
                    <li>{{ $t('tickets.bullet_two') }}</li>
                    <li>{{ $t('tickets.bullet_three') }}</li>
                    <li>{{ $t('tickets.bullet_four') }}</li>
                  </ul>
                </div>
              </div>
              <div v-if="showTickets">
                <ticket-detail-wrapper
                  v-for="(ticket, index) in tickets"
                  :key="ticket.id"
                  :ref="ticket.id"
                  :ticket="ticket"
                  :number="index + 1"
                  :ticket-category="ticketCategory(ticket.ticketCategoryID)"
                  @valid="handleValidTicket(ticket)"
                  @invalid="invalidTickets.push(ticket.id)"
                  @completeLater="handleValidTicket(ticket)"
                  @completeNow="invalidTickets.push(ticket.id)"
                />
              </div>
            </div>

            <div
              v-if="validationErrorMessage"
              class="alert alert-danger"
              role="alert"
            >
              {{ validationErrorMessage }}
            </div>
            <div class="tickets-shared">
              <button class="btn btn-primary" @click="gotoCheckout()">
                {{ $t('goto.checkout') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import TicketDetailWrapper from './TicketDetailWrapper'
import StepIndicator from './StepIndicator'
import Constants from '../services/constants'
import { getLocale } from '../locales/helper'
import { segmentEvent } from '../services/segmentTracking'

export default {
  components: { TicketDetailWrapper, StepIndicator },
  data() {
    return {
      invalidTickets: [],
      validationErrorMessage: null,
    }
  },
  watch: {
    showTickets(value) {
      this.setLoading(!value)
    },
  },
  computed: {
    ...mapState([
      'ticketsByCategory',
      'company',
      'headEvent',
      'config',
      'ticketCategories',
    ]),
    ...mapGetters('oidcStore', ['oidcIsAuthenticated', 'oidcUser']),
    showTickets() {
      return (
        this.ticketCategories.length !== 0 &&
        Object.keys(this.ticketsByCategory).length !== 0 &&
        this.headEvent
      )
    },
    tickets() {
      var tickets = []
      for (let currentTickets in this.ticketsByCategory) {
        tickets = tickets.concat(this.ticketsByCategory[currentTickets])
      }
      return tickets
    },
  },
  methods: {
    ...mapMutations([
      'setStep',
      'setLoading',
      'setQuantityForCategory',
      'setHeadEventSlug',
    ]),
    ...mapActions([
      'requestOrder',
      'fetchConfig',
      'fetchHeadEvent',
      'fetchTicketCategories',
      'updateTicketDiscounts',
    ]),
    handleApiError(error) {
      if (error.response) {
        this.validationErrorMessage = this.$t('errors.validationFailed')
      }
    },
    gotoCheckout() {
      if (this.invalidTickets.length === 0) {
        segmentEvent('Go to Checkout', {
          tickets: this.ticketsByCategory,
        })
        this.sendOrder()
      } else {
        for (let ticketID of this.invalidTickets) {
          this.$refs[ticketID][0].$refs.ticket.$validator.validateAll()
        }
        this.validationErrorMessage = this.$t('errors.validationFailed')
      }
    },
    sendOrder() {
      this.setLoading(true)

      this.requestOrder()
        .then((redirectURL) => {
          this.setLoading(false)
          const returnToShopUrl =
            window.location.origin +
            this.$router.resolve({
              name: 'TicketsChooser',
              params: { event: this.$store.state.headEventSlug },
            }).href
          ;(window.location.href = `${redirectURL}?return_to=${returnToShopUrl}`),
            '_blank'
        })
        .catch((error) => {
          this.handleApiError(error)
          this.setLoading(false)
        })
    },
    handleValidTicket(ticket) {
      this.invalidTickets = this.invalidTickets.filter((id) => id !== ticket.id)
      this.validationErrorMessage = null
    },
    ticketCategory(ticketCategoryID) {
      var categories = this.$store.state.ticketCategories
      return categories.find((obj) => obj.id === ticketCategoryID)
    },
    setTicketAttribute: function (
      ticketCategoryID,
      ticketID,
      attribute,
      value,
    ) {
      if (
        !this.$store.getters.ticketAttributeValue(
          ticketCategoryID,
          ticketID,
          attribute,
        )
      ) {
        this.$store.commit('setTicketAttribute', {
          ticketCategoryID,
          ticketID,
          attribute,
          value,
        })
      }
    },
    prefillFirstTicket() {
      const id = Object.keys(this.ticketsByCategory)[0]
      const ticket = this.ticketsByCategory[id][0]
      this.setTicketAttribute(id, ticket.id, 'email', this.oidcUser.email)
      this.setTicketAttribute(
        id,
        ticket.id,
        'firstName',
        this.oidcUser.given_name || '',
      )
      this.setTicketAttribute(
        id,
        ticket.id,
        'lastName',
        this.oidcUser.family_name || '',
      )
      if (this.oidcUser.company) {
        this.setTicketAttribute(
          id,
          ticket.id,
          'jobLevel',
          this.oidcUser.company.level || '',
        )
        this.setTicketAttribute(
          id,
          ticket.id,
          'companyFieldId',
          this.oidcUser.company.field_id || '',
        )
        this.setTicketAttribute(
          id,
          ticket.id,
          'companyIndustryId',
          this.oidcUser.company.industry_id || '',
        )
        this.setTicketAttribute(
          id,
          ticket.id,
          'companyName',
          this.oidcUser.company.name || '',
        )
        this.setTicketAttribute(
          id,
          ticket.id,
          'companyType',
          this.oidcUser.company.type || '',
        )
        this.setTicketAttribute(
          id,
          ticket.id,
          'companySize',
          this.oidcUser.company.size || '',
        )
      }
    },
  },
  created() {
    this.fetchConfig()

    /* Workaround for localisation being set to fallback (german)
       in this component, because language selection uses the route
       to parse out the /de/ or /en/ part, and due to OIDC calls in
       this component, the corresponding route part was not set. */
    const locale = getLocale()
    this.$i18n.locale = locale
    this.$validator.localize(locale)
  },
  mounted() {
    if (document.body.style.overflow === 'hidden') {
      document.body.style.overflow = 'unset'
    }

    const query = this.$route.query
    const ticketIDs = Object.keys(query).filter((t) => t.startsWith('ticket_'))

    if (
      ticketIDs.length !== 0 &&
      Object.keys(this.ticketsByCategory).length === 0
    ) {
      const headEventSlug = this.$route.params.event
      if (headEventSlug) {
        const setTickets = (ticketIDs, query) => {
          for (let ticketID of ticketIDs) {
            this.setQuantityForCategory({
              ticketCategoryID: ticketID.replace('ticket_', ''),
              quantity: query[ticketID],
            })
          }
          this.prefillFirstTicket()
        }

        this.fetchHeadEvent(headEventSlug).then(() => {
          if (query.promo_code) {
            this.updateTicketDiscounts(query.promo_code).then(() =>
              setTickets(ticketIDs, query),
            )
          } else {
            this.fetchTicketCategories(null).then(() =>
              setTickets(ticketIDs, query),
            )
          }
        })
      }
    } else {
      this.prefillFirstTicket()
    }

    this.setStep(Constants.TICKETS)
    this.setLoading(false)
  },
}
</script>
