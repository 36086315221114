import Vue from 'vue'
import VueI18n from 'vue-i18n'
import deJson from './de.json'
import enJson from './en.json'
import { enCountries, deCountries } from '../services/countries'

Vue.use(VueI18n)

function loadLocaleMessages() {
  var messages = {}
  messages['de'] = { ...deJson, ...{ countries: deCountries } }
  messages['en'] = { ...enJson, ...{ countries: enCountries } }
  return messages
}

const availableLocales = ['de', 'en']
const messages = loadLocaleMessages()

function getLocale() {
  let locale
  const pathArray = window.location.pathname.split('/')
  for (let entry of pathArray) {
    if (availableLocales.includes(entry)) {
      locale = entry
    }
  }
  return locale || 'de'
}

const i18n = new VueI18n({
  locale: getLocale(),
  fallbackLocale: 'de',
  messages: loadLocaleMessages(),
})

export { getLocale, i18n, messages }
