<template>
  <TicketDetail
    ref="ticket"
    :title="ticketCategory.attributes.title"
    :location="ticketCategory.attributes.location"
    :ticket="ticket"
    :period="ticketCategory.attributes.period"
    :number="number"
    :email-rules="emailRules"
    :company-options="$store.state.config"
    @attributeUpdate="updateTicketAttribute"
    @valid="$emit('valid')"
    @invalid="$emit('invalid')"
    @completeLater="$emit('completeLater')"
    @completeNow="$emit('completeNow')"
    @complete="$refs.ticket.completed = true"
  />
</template>

<script>
import TicketDetail from './TicketDetail'
// TODO: Wrapper can be removed. It was used when ticketing lived in myomr
// There were different wrapper for ticketing app and ticket_edit app.
export default {
  components: { TicketDetail },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    ticketCategory: {
      type: Object,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
  },
  computed: {
    emailRules() {
      if (
        this.$store.state.headEvent.attributes.allow_duplicate_email === false
      ) {
        return 'required|email|validEmailForEvent'
      } else {
        return 'required|email'
      }
    },
  },
  methods: {
    updateTicketAttribute(data) {
      this.$store.commit('setTicketAttribute', {
        ticketCategoryID: this.ticket.ticketCategoryID,
        ticketID: this.ticket.id,
        attribute: data.attribute,
        value: data.value,
      })
    },
  },
}
</script>
