<template>
  <div>
    <div
      v-if="!accepted && showModal"
      id="js-modal-container"
      class="modal-container"
      @click.self="closeModal()"
    >
      <div class="decline-modal">
        <div class="close-button" @click.prevent="closeModal()">
          <img src="@/assets/images/x.svg" alt="" />
        </div>

        <div class="hero-image">
          <img
            src="@/assets/images/rejection.jpg"
            srcset="
              @/assets/images/rejection@2x.jpg 2x,
              @/assets/images/rejection@3x.jpg 3x
            "
            alt=""
          />
        </div>
        <h3>{{ $t('invite.inviteRejectionTitle') }}</h3>
        <textarea
          v-model="message"
          class="message"
          rows="5"
          :placeholder="$t('invite.textPlaceHolder')"
        ></textarea>
        <button
          type="submit"
          class="btn send-message"
          @click.prevent="sendMessage()"
        >
          {{ $t('invite.cta.sendMessage') }}
        </button>
      </div>
    </div>

    <div v-if="!isUsed" class="buttons">
      <button
        v-scroll-to="'#tickets-chooser'"
        type="submit"
        class="btn btn-danger"
        @click.prevent="accept(true)"
      >
        {{ $t('invite.cta.accept') }}
      </button>

      <button
        type="submit"
        class="btn btn-light"
        @click.prevent="accept(false)"
      >
        {{ $t('invite.cta.decline') }}
      </button>
    </div>
  </div>
</template>

<script>
import api from '../services/api'
export default {
  prop: {
    inviteAccepted: {
      type: Boolean,
    },
  },
  data() {
    return {
      message: '',
      accepted: this.inviteAccepted,
      showModal: false,
    }
  },
  computed: {
    isUsed() {
      return this.$store.state.inviteeIsUsed
    },
  },
  methods: {
    sendMessage() {
      api
        .setInviteMessage(this.$store.state.inviteeToken, this.message)
        .then(() => {
          this.$toasted.global.customSuccess(
            this.$t('invite.toasts.messageReceived'),
          )
          this.showModal = false
        })
        .catch(() => {
          this.$toasted.error(this.$t('invite.toasts.messagError'))
          this.showModal = false
        })
    },
    accept(value) {
      if (value) {
        this.$emit('accepted')
        this.$toasted.global.customSuccess(this.$t('invite.toasts.accept'))
      } else {
        this.$emit('declined')
        this.showModal = true
      }
      this.accepted = value
    },
    closeModal() {
      this.showModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  button {
    margin: 10px;
  }

  .btn-light {
    color: #212529;
  }
}

.hideModal {
  display: none !important;
}

.modal-container {
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .hero-image {
    padding: 20px 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  .decline-modal {
    text-align: center;
    background: #fff;
    color: black;
    padding: 40px;
    position: relative;
    width: 90%;
    max-width: 518px;

    .close-button {
      position: absolute;
      right: 0;
      top: -5px;
      padding: 10px;
      cursor: pointer;
    }
  }

  p {
    font-size: 14px;
    text-align: center;
  }
}

.message {
  width: 100%;
  margin-bottom: 10px;
  background: #ededed;
  border: none;
  font-size: 14px;
  padding: 10px;
}

.send-message {
  width: 100%;
  background: #e91561;
}
</style>
