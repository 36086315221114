<template>
  <div class="tickets-box tickets-checkout__items">
    <div class="tickets-box__title">
      <h5>Ticket #{{ number }}</h5>
    </div>
    <div class="tickets-box__head">
      <h3>{{ title }}</h3>
      <div class="tickets-box__head--meta">
        <span
          class="tickets-box__head--meta__date myomr-calendar myomr-calendar__white"
          >{{ period }}</span
        >
        <span v-if="location" class="tickets-box__head--meta__location">
          in {{ location }}</span
        >
      </div>
    </div>

    <transition name="collapse">
      <div
        v-if="!(completeLater || completed)"
        class="vue-collapse"
        style="min-height: none"
      >
        <div class="tickets-box__inner tickets-box__form tickets-box__white">
          <div class="tickets-box__form--description">
            {{ $t('ticket.complete') }}
          </div>

          <div class="form-row">
            <CustomSelect
              :value="ticket.ownership"
              :object="`ticket_${ticket.id}`"
              attribute="ownership"
              wrapper-class="col-md-6"
              :options="ticketOwnershipsWithPlaceholder"
              rules="required"
              @input="valueChanged($event, 'ownership')"
            />
          </div>
          <CustomInput
            :value="ticket.email"
            :object="`ticket_${ticket.id}`"
            attribute="email"
            :rules="emailRules"
            wrapper-class="mb-4"
            @input="valueChanged($event, 'email')"
          />
          <CustomInput
            :value="ticket.phone"
            :object="`ticket_${ticket.id}`"
            attribute="phone"
            rules="required|numeric"
            wrapper-class="mb-4"
            @input="valueChanged($event, 'phone')"
          />
          <div class="form-row">
            <CustomSelect
              :value="ticket.salutation"
              :object="`ticket_${ticket.id}`"
              attribute="salutation"
              wrapper-class="col-md-6"
              :options="salutationsWithPlaceholder"
              rules="required|alpha"
              @input="valueChanged($event, 'salutation')"
            />

            <CustomSelect
              :value="ticket.title"
              :object="`ticket_${ticket.id}`"
              attribute="title"
              wrapper-class="col-md-6"
              :options="titlesWithPlaceholder"
              @input="valueChanged($event, 'title')"
            />
          </div>

          <CustomInput
            :value="ticket.firstName"
            :object="`ticket_${ticket.id}`"
            attribute="firstName"
            rules="required"
            @input="valueChanged($event, 'firstName')"
          />

          <CustomInput
            :value="ticket.lastName"
            :object="`ticket_${ticket.id}`"
            attribute="lastName"
            rules="required"
            @input="valueChanged($event, 'lastName')"
          />

          <div class="form-row">
            <CustomSelect
              :value="ticket.country"
              :object="`ticket_${ticket.id}`"
              attribute="country"
              :options="countryCodeData"
              rules="required"
              @input="valueChanged($event, 'country')"
            />
          </div>

          <div class="form-row">
            <CustomSelect
              :value="ticket.jobLevel"
              :object="`ticket_${ticket.id}`"
              attribute="jobLevel"
              rules="required"
              :options="collectionWithPlaceholder('job_levels')"
              @input="valueChanged($event, 'jobLevel')"
            />

            <CustomInput
              v-if="showSelect"
              :value="ticket.companyName"
              rules="required"
              :object="`ticket_${ticket.id}`"
              wrapper-class="col-md-12"
              attribute="companyName"
              @input="valueChanged($event, 'companyName')"
            />

            <CustomSelect
              v-if="showSelect"
              :value="ticket.companySize"
              rules="required"
              :object="`ticket_${ticket.id}`"
              attribute="companySize"
              :options="collectionWithPlaceholder('company_sizes')"
              @input="valueChanged($event, 'companySize')"
            />

            <CustomSelect
              v-if="showSelect"
              :value="ticket.companyType"
              rules="required"
              :object="`ticket_${ticket.id}`"
              attribute="companyType"
              :options="collectionWithPlaceholder('company_types')"
              @input="valueChanged($event, 'companyType')"
            />

            <CustomSelect
              v-if="showSelect"
              :value="ticket.companyIndustryId"
              rules="required"
              :object="`ticket_${ticket.id}`"
              attribute="companyIndustryId"
              :options="collectionWithPlaceholder('company_industries')"
              @input="valueChanged($event, 'companyIndustryId')"
            />

            <CustomSelect
              v-if="showSelect && ticket.companyIndustryId"
              :value="ticket.companyFieldId"
              rules="required"
              :object="`ticket_${ticket.id}`"
              attribute="companyFieldId"
              :options="availableCompanyFields"
              @input="valueChanged($event, 'companyFieldId')"
            />

            <CustomSelect
              v-if="showSelect"
              :value="ticket.companyDepartment"
              rules="required"
              :object="`ticket_${ticket.id}`"
              attribute="companyDepartment"
              :options="collectionWithPlaceholder('company_departments')"
              @input="valueChanged($event, 'companyDepartment')"
            />

            <CustomInput
              v-if="showSelect"
              :value="ticket.position"
              rules="required"
              wrapper-class="col-md-12"
              :object="`ticket_${ticket.id}`"
              attribute="position"
              @input="valueChanged($event, 'position')"
            />
          </div>
        </div>
      </div>
    </transition>

    <div v-if="!completed" class="tickets-box__foot tickets-box__white">
      <div class="row">
        <div class="col-md-6">
          <div class="tickets-box__foot--left tickets-box__foot--left-fixed">
            <div class="custom-control custom-checkbox">
              <input
                :id="`ticket-later=${ticket.id}`"
                type="checkbox"
                class="custom-control-input"
                @click="toggleCompleteLater()"
              />
              <label
                class="custom-control-label"
                :for="`ticket-later=${ticket.id}`"
                >{{ $t('ticket.completeLater') }}</label
              >
            </div>
          </div>
        </div>
        <div v-if="!completeLater" class="col-md-6 text-right">
          <div class="tickets-box__foot--right">
            <button class="btn btn-secondary" type="button" @click="complete()">
              {{ $t('button.accept') }}
            </button>
          </div>
        </div>
      </div>

      <div v-if="!completeLater && getFfwdConsent" class="row mt-2">
        <div class="col-md-12">
          <div class="custom-control custom-checkbox">
            <input
              :id="`ticket-omr-ffwd-consent=${ticket.id}`"
              type="checkbox"
              :checked="ticket.ffwdConsent"
              class="custom-control-input"
              @click="toggleFfwdConsent()"
            />
            <label
              class="custom-control-label"
              :for="`ticket-omr-ffwd-consent=${ticket.id}`"
              >{{ $t('fields.ffwd_consent') }}</label
            >
          </div>
        </div>
      </div>

      <div v-if="!completeLater" class="row mt-2">
        <div class="col-md-12">
          <div class="custom-control custom-checkbox">
            <input
              :id="`ticket-omr-match-consent=${ticket.id}`"
              type="checkbox"
              :checked="ticket.omrMatchConsent"
              class="custom-control-input"
              @click="toggleOmrMatchConsent()"
            />
            <label
              class="custom-control-label"
              :for="`ticket-omr-match-consent=${ticket.id}`"
              >{{ $t('fields.omrMatchConsent') }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="completed" class="tickets-box__foot tickets-box__white">
      <div class="row">
        <div class="col-md-6">
          <div class="tickets-box__foot--left">
            <p>{{ ticket.firstName }} {{ ticket.lastName }}</p>
            <p>{{ translatedJobLevel }}</p>
            <p>{{ ticket.email }}</p>
            <p v-if="ticket.ticket_download_url">
              <a :href="ticket.ticket_download_url">{{
                $t('ticket.download_ticket')
              }}</a>
            </p>
            <p v-if="ticket.passbook_download_url">
              <a :href="ticket.passbook_download_url">{{
                $t('ticket.download_pkpass')
              }}</a>
            </p>
            <p v-if="ticket.masterclass_url">
              <a :href="ticket.masterclass_url">{{
                $t('ticket.manage_masterclasses')
              }}</a>
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="tickets-box__foot--right tickets-box__foot--right_center">
            <img
              v-if="ticket.qrcode_url"
              :src="ticket.qrcode_url"
              class="tickets-box__foot--right__qrcode"
            />
            <a
              v-if="isEditable"
              class="btn"
              @click.prevent="completed = false"
              >{{ $t('ticket.edit') }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { countryCodes, enCountries, deCountries } from '../services/countries'
import { titles, salutations, ticketOwnerships } from '../services/collections'
import CustomInput from './fields/CustomInput'
import CustomSelect from './fields/CustomSelect'
import { mapGetters } from 'vuex'
import { getCookieValue } from '../helpers/cookies'
import { segmentPageView } from '../services/segmentTracking'

export default {
  components: { CustomInput, CustomSelect },
  props: {
    initial_state_completed: {
      type: Boolean,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    ticket: {
      type: Object,
      required: true,
    },
    emailRules: {
      type: String,
      required: true,
    },
    companyOptions: {
      required: true,
      type: Object,
    },
    title: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      completeLater: false,
      completed: this.initial_state_completed,
    }
  },
  computed: {
    ...mapGetters(['getFfwdConsent']),
    allValid() {
      return Object.keys(this.fields).every(
        (f) => this.fields[f] && this.fields[f].valid,
      )
    },
    availableCompanyFields() {
      const allFields = this.companyOptions['company_fields']
      return [
        {
          value: '',
          text: this.$t('fields.company_fields'),
        },
        ...allFields.filter(
          (field) => field.industry_id == this.ticket.companyIndustryId,
        ),
      ]
    },
    translatedJobLevel() {
      const selectedLevel = this.companyOptions.job_levels.find((x) => {
        return x.value === this.ticket.jobLevel
      })
      return selectedLevel ? selectedLevel.text : null
    },
    ticketOwnershipsWithPlaceholder() {
      return ticketOwnerships(this.$i18n)
    },
    salutationsWithPlaceholder() {
      return salutations(this.$i18n)
    },
    titlesWithPlaceholder() {
      return titles(this.$i18n)
    },
    showSelect() {
      return !!this.ticket.jobLevel && this.ticket.jobLevel !== 'student'
    },
    countryCodeData() {
      let finalCodes = [{ value: '', text: this.$i18n.t('fields.country') }]
      let locale = this.$i18n.locale
      for (let countryCode of countryCodes) {
        if (locale == 'en') {
          finalCodes.push({
            value: countryCode,
            text: enCountries[countryCode],
          })
        } else {
          finalCodes.push({
            value: countryCode,
            text: deCountries[countryCode],
          })
        }
      }
      return finalCodes
    },
  },
  mounted() {
    setTimeout(function () {
      segmentPageView('Tickets: TicketsDetails')
    }, 500)
  },
  watch: {
    completeLater(isCompleteLater) {
      var event
      if (isCompleteLater) {
        this.valueChanged('', 'email')
        event = 'completeLater'
      } else {
        event = 'completeNow'
      }
      this.$emit(event)
    },
    allValid(isValid) {
      const event = isValid ? 'valid' : 'invalid'
      this.$emit(event)
    },
  },
  methods: {
    valueChanged(value, attribute) {
      this.$emit('attributeUpdate', { value, attribute })
    },
    toggleOmrMatchConsent() {
      this.valueChanged(!this.ticket.omrMatchConsent, 'omrMatchConsent')
    },
    toggleFfwdConsent() {
      this.valueChanged(!this.ticket.ffwdConsent, 'ffwdConsent')
    },
    toggleCompleteLater() {
      this.completeLater = !this.completeLater
    },
    collectionWithPlaceholder(name) {
      return [
        { value: '', text: this.$t(`fields.${name}`) },
        ...this.companyOptions[name],
      ]
    },
    complete() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('complete')
        }
      })
    },
  },
}
</script>
