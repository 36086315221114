<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'OidcCallback',
  methods: {
    ...mapActions('oidcStore', ['oidcSignInCallback']),
    extractLocaleFromUrl(url) {
      const params = url.substring(url.indexOf('?') + 1).split('&')
      for (var i = 0; i < params.length; ++i) {
        var currentParam = params[i].split('=', 2)
        if (currentParam[0].startsWith('locale')) {
          return decodeURIComponent(currentParam[1].replace(/\+/g, ' '))
        }
      }
      return ''
    },
  },
  created() {
    this.oidcSignInCallback()
      .then((redirectPath) => {
        if (redirectPath.indexOf('locale') !== -1) {
          // set locale since its blank for oidc callback
          // when coming from webcomponent redirect
          this.$i18n.locale = this.extractLocaleFromUrl(redirectPath)
        }
        this.$router.push(redirectPath)
      })
      .catch(() => this.$router.push('/signin-oidc-error')) // Handle errors any way you want
  },
}
</script>
