import { WebStorageStateStore } from 'oidc-client'
import env from './env'

const envSettings = {
  staging: {
    authority: 'https://my.omr.ninja',
    client_id: 'WEghIXHBzgTvM0dYbKjhGECcnhoC3ESrRJCZdGT9Ers',
  },
  production: {
    authority: 'https://my.omr.com',
    client_id: 'WEghIXHBzgTvM0dYbKjhGECcnhoC3ESrRJCZdGT9Ers',
  },
  development: {
    authority: process.env.VUE_APP_OIDC_AUTHORITY || 'https://my.omr.ninja',
    client_id:
      process.env.VUE_APP_OIDC_CLIENT_ID ||
      'WEghIXHBzgTvM0dYbKjhGECcnhoC3ESrRJCZdGT9Ers',
  },
}

const origin = (window.location && window.location.origin) || ''

export const authority = envSettings[env].authority

export const oidcSettings = {
  ...envSettings[env],
  automaticSilentRenew: true,
  redirect_uri: `${origin}/oidc-callback`,
  response_type: 'id_token token',
  scope: 'email profile openid ticket_shop api',
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  silent_redirect_uri: `${origin}/silent-renew-oidc.html`,
}
