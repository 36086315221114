import axios from 'axios'
import { i18n } from '../locales/helper'

import { authority } from '@/config/oidc'

const config = (token) => {
  var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  return {
    baseURL: authority,
    withCredentials: false,
    headers,
  }
}

const apiClient = axios.create(config(null))

function decamelize(str, separator) {
  separator = typeof separator === 'undefined' ? '_' : separator

  return str
    .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
    .toLowerCase()
}

export const decamelizeObj = (obj) => {
  var decamelized = {}
  for (let key in obj) {
    decamelized[decamelize(key)] = obj[key]
  }
  return decamelized
}

export const decamelizeTickets = (ticketsObject) => {
  var tickets = []
  for (let id in ticketsObject) {
    tickets = tickets.concat(ticketsObject[id])
  }
  return tickets.map((ticket) => decamelizeObj(ticket))
}

/** API Calls **/
export default {
  getTicketCategories(params) {
    params.locale = i18n.locale
    if (params.promoCode && params.inviteeToken) {
      params.promoCode = null
    }

    return apiClient.get('/api/v1/ticket_categories', { params: params })
  },
  getTicketPartner(slug, inviteeToken) {
    let params = {
      invitee_token: inviteeToken,
      locale: i18n.locale,
    }

    return apiClient.get(`/api/v1/partner/${slug}`, { params: params })
  },
  getInvitee(token) {
    return apiClient.get(`/api/v1/invitees/${token}`)
  },
  getConfig(accessToken) {
    const headers = config(accessToken).headers
    return apiClient.get('/api/v1/ticket_config', {
      params: { locale: i18n.locale },
      headers,
    })
  },
  getHeadEvent(headEventSlug) {
    return apiClient.get(`/api/v1/events/${headEventSlug}`, {
      params: { locale: i18n.locale },
    })
  },
  validateEmail(params, state) {
    return apiClient.post(
      '/api/v1/tickets/email_check',
      params,
      config(state.oidcStore.access_token),
    )
  },
  checkoutForFree(orderToken) {
    const params = {
      locale: i18n.locale,
    }

    return apiClient.post(`web/orders/${orderToken}/checkout/finish`, params)
  },
  setAccepted(token, value) {
    const params = {
      accept: value,
    }

    return apiClient.put(`/api/v1/invitees/${token}`, params)
  },
  setInviteMessage(token, message) {
    const params = { message }

    return apiClient.put(`/api/v1/invitees/${token}`, params)
  },
  createOrder(tickets, validPromoCode, inviteeToken, order_id, token) {
    const params = {
      tickets: decamelizeTickets(tickets),
      locale: i18n.locale,
      promo_code: validPromoCode,
      invitee_token: inviteeToken,
      order_id: order_id,
    }
    return apiClient.post('/api/v1/orders', params, config(token))
  },
}
