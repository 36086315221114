<template>
  <div :class="`form-group form-label-group ${wrapperClass}`">
    <input
      :id="idString"
      v-validate="rules"
      :type="type"
      :data-vv-delay="debouncedMilliSeconds"
      :name="attribute"
      :value="value"
      :class="{ 'form-control': true, 'is-invalid': errors.has(attribute) }"
      :placeholder="labelString"
      @input="valueChanged"
    />
    <label :for="idString" :class="{ required: required() }">{{
      labelString
    }}</label>
    <span v-show="errors.has(attribute)" class="invalid-feedback">{{
      errors.first(attribute)
    }}</span>
    <small
      v-if="showHelpBlock || hasEmailUnqinessValidation"
      v-show="!errors.has(attribute)"
      :id="`#{attribute}HelpBlock`"
      class="form-text text-muted"
    >
      {{ $t(`ticket.${attribute}HelpText`) }}
    </small>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    object: {
      type: String,
      required: true,
    },
    showHelpBlock: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    attribute: {
      type: String,
      required: true,
    },
    value: {},
    rules: {},
    wrapperClass: null,
  },
  computed: {
    hasEmailUnqinessValidation() {
      return (
        this.rules !== undefined &&
        this.rules.match(/validEmailForEvent/gi) !== null
      )
    },
    debouncedMilliSeconds() {
      if (this.hasEmailUnqinessValidation) {
        return 1000
      } else {
        return 0
      }
    },
    idString() {
      return `${this.object}_${this.attribute}`
    },
    labelString() {
      return this.$t(`fields.${this.attribute}`)
    },
  },
  methods: {
    valueChanged(e) {
      this.$emit('input', e.target.value)
    },
    required() {
      if (this.rules === undefined) return false
      return this.rules.match(/required/gi) !== null
    },
  },
}
</script>
