import env from './env'

const url = (locale, slug) => {
  return {
    ticketDetails: `${host(env)}/${locale}/${slug}/details`,
  }
}

function host(env) {
  if (env === 'development') {
    return 'http://tickets.omr.io'
  } else if (env === 'staging') {
    return 'https://tickets.omr.ninja'
  } else {
    return 'https://tickets.omr.com'
  }
}

function urlFor(name, locale, eventSlug) {
  return url(locale, eventSlug)[name]
}

export default urlFor
