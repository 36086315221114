<template>
  <div>
    <div v-if="!loading && validTicketCategory === false">
      <QuotaUsedUp />
    </div>
    <div v-if="quotaExpired">
      <QuotaExpired />
    </div>
    <div v-else>
      <div v-if="ticketPartner && isVodafoneQuota" class="vodafone_quota">
        <div class="vodafone_quota__inner">
          <div class="vodafone_quota__logo"></div>
          <span class="vodafone_quota__text">Vodafone auf der #OMR24</span>
        </div>
      </div>
      <section
        v-if="ticketPartner"
        id="section-ticket-partner"
        class="section-ticket-partner"
      >
        <div class="section-ticket-partner__inner">
          <div class="container">
            <div class="row">
              <div
                class="col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2"
              >
                <div class="ticket-partner__content">
                  <div
                    v-if="!isVodafoneQuota && (partnerLogoUrl || eventLogoUrl)"
                    class="header-logo-section"
                  >
                    <div v-if="partnerLogoUrl" class="header-logo-container">
                      <img
                        :src="partnerLogoUrl"
                        height="50px"
                        class="ticket-partner__content--name__logo header-logo"
                      />
                    </div>

                    <span v-if="partnerLogoUrl && eventLogoUrl"
                      ><img src="@/assets/images/x-partner.svg" alt=""
                    /></span>

                    <div v-if="eventLogoUrl" class="header-logo-container">
                      <img
                        :src="eventLogoUrl"
                        height="50px"
                        class="ticket-partner__content--name__logo header-logo"
                      />
                    </div>
                  </div>

                  <h1 class="ticket-partner__content--name">
                    {{ title }}
                  </h1>

                  <h2 class="ticket-partner__content--title">{{ headline }}</h2>

                  <div class="ticket-partner__content--description">
                    <p v-html="description"></p>
                  </div>
                </div>
                <div v-if="previewTickets" class="tickets tickets-partner">
                  <div class="tickets-items">
                    <PartnerTicketPreview
                      v-for="(ticket, index) in previewTickets"
                      :key="index"
                      v-scroll-to="'#tickets-chooser'"
                      :title="ticket.attributes.title"
                      :description="ticket.attributes.description"
                      :period="ticket.attributes.period"
                      :location="ticket.attributes.location"
                      :tickets-per-category="ticket.attributes.times_usable"
                      :discount="parseFloat(ticket.attributes.discount)"
                      :discount-amount="
                        parseFloat(ticket.attributes.discount_amount)
                      "
                      :price="parseFloat(ticket.attributes.price)"
                      :offer-end-time="ticket.attributes.offer_end_time"
                    />
                  </div>
                </div>
                <Invite
                  v-if="showInvite"
                  :invite-accepted="inviteAccepted"
                  @accepted="$emit('accepted')"
                  @declined="$emit('declined')"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-scroll-to="'#tickets-chooser'"
          class="section-ticket-partner__mouse"
        >
          <img src="@/assets/images/mouse-scroll.svg" alt="MouseScroll" />
        </div>
      </section>
      <TicketCategories
        v-if="ticketPartner"
        id="tickets-beginning"
        :should-load-tickets="false"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import TicketCategories from './TicketCategories'
import Invite from './Invite'
import QuotaUsedUp from './QuotaUsedUp'
import QuotaExpired from './QuotaExpired'
import PartnerTicketPreview from './PartnerTicketPreview'

export default {
  components: {
    Invite,
    TicketCategories,
    PartnerTicketPreview,
    QuotaUsedUp,
    QuotaExpired,
  },
  props: {
    inviteAccepted: {
      type: Boolean,
    },
    showInvite: {
      type: Boolean,
      default: false,
    },
    slug: {
      type: String,
      optional: true,
    },
  },
  data() {
    return {
      eventLogoUrl: null,
    }
  },
  methods: {
    ...mapActions([
      'fetchTicketPartner',
      'fetchTicketCategories',
      'updateTicketDiscounts',
      'fetchHeadEvent',
    ]),
    ...mapMutations(['setLoading']),
  },
  computed: {
    ...mapState([
      'ticketPartner',
      'validTicketCategory',
      'loading',
      'quotaExpired',
    ]),
    title() {
      return this.ticketPartner.attributes.title
    },
    headline() {
      return this.ticketPartner.attributes.headline
    },
    partnerLogoUrl() {
      return this.ticketPartner.attributes.logo
    },
    description() {
      return this.ticketPartner.attributes.description
    },
    previewTickets() {
      if (this.ticketPartner.attributes.ticket_categories) {
        return this.ticketPartner.attributes.ticket_categories.data
      } else {
        return null
      }
    },
    isVodafoneQuota() {
      return this.ticketPartner.attributes.vodafone_quota || false
    },
  },
  mounted() {
    const slug = this.slug || this.$route.params.slug
    if (slug) {
      this.fetchTicketPartner(slug).then((attributes) => {
        this.fetchHeadEvent(attributes.event_slug).then((result) => {
          this.eventLogoUrl = result.data.data.attributes.logo
          this.updateTicketDiscounts(attributes.promo_code)
            .then(() => {
              this.setLoading = false
            })
            .catch(() => {
              this.setLoading = false
              this.fetchTicketCategories(null)
            })
        })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.header-logo-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 30px;
  span {
    img {
      padding: 0 10px;
    }
  }
}

.header-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  width: 120px;
  height: 120px;
  background: #fff;
  img {
    width: 80px;
    height: auto;
  }
}

.vodafone_quota {
  background-color: #ffffff;
}

.vodafone_quota__inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 100px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}

.vodafone_quota__logo {
  background: url('../assets/images/vf-business.png') transparent no-repeat
    center center;
  background-size: 200px;
  width: 200px;
  height: 100px;
}

.vodafone_quota__text {
  color: #ee3023;
  font-weight: 600;
  margin: 0 40px;
  white-space: nowrap;
  line-height: 50px;
}
</style>
