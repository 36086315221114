<template>
  <div id="step-indicator" class="step-indicator">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="row no-gutters">
            <div class="col-3 col-md-2">
              <div
                class="step-indicator__item step-indicator__item-first"
                :class="{ active: step > 1, current: step == 1 }"
                @click="goTo(1)"
              >
                <span>{{ $t('stepIndicator.chooseTickets') }}</span>
              </div>
            </div>
            <div class="col-3 col-md-4">
              <div
                class="step-indicator__item"
                :class="{ active: step > 2, current: step == 2 }"
                @click="goTo(2)"
              >
                <span>{{ $t('stepIndicator.ticketData') }}</span>
              </div>
            </div>
            <div class="col-3 col-md-4">
              <div class="step-indicator__item">
                <span>{{ $t('stepIndicator.invoiceData') }}</span>
              </div>
            </div>
            <div class="col-3 col-md-2">
              <div class="step-indicator__item step-indicator__item-last">
                <span>{{ $t('stepIndicator.payment') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Constants from '../services/constants'
export default {
  computed: {
    step() {
      return this.$store.state.step
    },
  },
  methods: {
    goTo(step) {
      if (step == Constants.TICKET_CATEGORIES) {
        this.$router.push({
          name: 'TicketsChooser',
          params: { event: this.$store.state.headEventSlug },
        })
      } else if (step == Constants.TICKETS) {
        this.$router.push({ name: 'Tickets' })
      }
    },
  },
}
</script>
