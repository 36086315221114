<template>
  <div id="tickets" class="tickets" :class="headEventClass">
    <div v-if="!isWebComponent" class="tickets-hero">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="tickets-hero__inner">
              <h1>{{ $t('ticketCategories.header') }}</h1>
              <p
                v-html="linkify(headEvent && headEvent.attributes.description)"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showTickets">
      <div class="tickets-header">
        <div :class="isWebComponent ? 'container-full' : 'container'">
          <h2 id="checkoutheader" class="h3">
            {{ !!headEvent && headEvent.attributes.name }}
          </h2>
        </div>
      </div>
      <div :class="headEventClass">
        <div
          v-if="showTickets"
          id="tickets-chooser"
          class="tickets-chooser tickets-items"
        >
          <div :class="isWebComponent ? 'container-full' : 'container'">
            <div
              v-for="ticketCategory in ticketCategories"
              :key="ticketCategory.id"
            >
              <TicketCategory
                v-if="showTicketCategory(ticketCategory)"
                :ticket-category="ticketCategory"
                @quantityChangeValid="quantityChangeValid"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="tickets-footer">
        <div :class="isWebComponent ? 'container-full' : 'container'">
          <div class="tickets-footer__row">
            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="tickets-footer__row--left">
                  <form
                    v-if="showCodeInput && !inviteeToken"
                    class="tickets-footer__codeinput"
                    @submit.prevent="handleCodeSubmit()"
                  >
                    <div class="input-group">
                      <div class="form-label-group">
                        <input
                          id="promoCode"
                          v-model="promoCode"
                          v-validate="'alpha_dash'"
                          type="text"
                          name="promoCode"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors.has('promoCode'),
                          }"
                          placeholder="Promocode"
                        />
                        <label for="promoCode">{{
                          $t('fields.promoCode')
                        }}</label>
                        <span
                          v-show="errors.has('promoCode')"
                          class="invalid-feedback"
                          >{{ errors.first('promoCode') }}</span
                        >
                      </div>
                      <div class="input-group-append">
                        <button
                          type="submit"
                          class="btn btn-input-group-submit"
                        >
                          {{ $t('ticketCategories.redeem') }}
                        </button>
                      </div>
                    </div>
                  </form>
                  <div v-else-if="!inviteeToken">
                    <p v-html="promoCodeMessage"></p>
                    <a
                      v-if="!showCodeInput"
                      class="tickets-footer__showcodeinput"
                      href="#"
                      @click.prevent="showCodeInput = true"
                      >{{ $t('ticketCategories.usePromoCode') }}</a
                    >
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="tickets-footer__row--right">
                  <div class="row">
                    <div class="col-6">
                      <div class="tickets-footer__row--right_keys">
                        <p class="tickets-footer__row--right__netkey">
                          {{ $t('ticketCategories.net') }}
                        </p>
                        <p>{{ $t('ticketCategories.tax') }}</p>
                        <p>{{ $t('ticketCategories.total') }}</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="tickets-footer__row--right_values">
                        <p
                          class="tickets-footer__row--right__netvalue"
                          v-html="
                            $t('ticketCategories.priceThin', {
                              price: formatPrice(totalNet),
                            })
                          "
                        />
                        <p
                          v-html="
                            $t('ticketCategories.priceThin', {
                              price: formatPrice(totalTaxes),
                            })
                          "
                        />
                        <p
                          v-html="
                            $t('ticketCategories.priceThin', {
                              price: formatPrice(totalGross),
                            })
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="custom-control custom-checkbox text-right">
                  <input
                    id="terms_and_conditions_input"
                    v-model="termsAndConditionsAccepted"
                    v-validate="'required'"
                    data-qa="accept-legal"
                    type="checkbox"
                    :class="{
                      'custom-control-input': true,
                      'is-invalid': errors.has('termsAndConditionsAccepted'),
                    }"
                    name="termsAndConditionsAccepted"
                  />
                  <label
                    class="custom-control-label"
                    for="terms_and_conditions_input"
                    v-html="$t('ticketCategories.termsAndConditions')"
                  ></label>
                </div>
              </div>
            </div>
            <div
              v-if="validationErrorMessage"
              class="alert alert-danger mt-4"
              role="alert"
            >
              {{ validationErrorMessage }}
            </div>
            <div class="row">
              <div class="col-md-5 col-lg-6">
                <div
                  class="tickets-footer__row--left tickets-footer__row--left_payments"
                >
                  <ul class="tickets-footer__row--left_payments--methods">
                    <li>
                      <img src="@/assets/images/paypal.svg" alt="PayPal" />
                    </li>
                    <li>
                      <img
                        src="@/assets/images/mastercard.svg"
                        alt="Mastercard"
                      />
                    </li>
                    <li>
                      <img
                        src="@/assets/images/visa.jpg"
                        width="100%"
                        height="100%"
                        alt="VISA"
                      />
                    </li>
                    <li>
                      <img
                        src="@/assets/images/amex.svg"
                        alt="American Express"
                      />
                    </li>
                    <li>
                      <img
                        src="@/assets/images/sepa.jpg"
                        width="100%"
                        height="100%"
                        alt="Lastschrift"
                      />
                    </li>
                    <li>
                      <img
                        src="@/assets/images/invoice.png"
                        width="100%"
                        height="100%"
                        alt="Kauf auf Rechnung"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-7 col-lg-6">
                <div class="tickets-footer__row--right">
                  <div class="tickets-footer__row--right_actions">
                    <button
                      class="btn btn-primary"
                      :disabled="!continueToTicketsDetails"
                      @click="goToTickets()"
                    >
                      {{ $t('goto.ticketDetails') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 omr-disclaimer">
                {{ $t('goto.disclaimer') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-dupe-v-else-if */
import { mapActions, mapState, mapMutations } from 'vuex'
import TicketCategory from './TicketCategory'
import Constants from '../services/constants'
import { getLocale } from '../locales/helper'
import urlFor from '../config/url'
import { linkify } from '../helpers/linkify'

export default {
  components: { TicketCategory },
  props: {
    isWebComponent: { type: Boolean, default: false },
    shouldLoadTickets: { type: Boolean, default: true },
    loadedPromoCode: { type: String, optional: true },
    webcomponentLocale: { type: String, optional: true },
  },
  data() {
    return {
      termsAndConditionsAccepted: false,
      showCodeInput: true,
      invalidTicketQuantities: [],
      promoCode: this.loadedPromoCode,
      validationErrorMessage: null,
    }
  },
  methods: {
    linkify,
    quantityChangeValid(isValid, categoryID) {
      if (isValid) {
        this.invalidTicketQuantities = this.invalidTicketQuantities.filter(
          (id) => id !== categoryID,
        )
      } else {
        this.invalidTicketQuantities.push(categoryID)
      }
    },
    ...mapActions(['updateTicketDiscounts', 'fetchHeadEvent']),
    ...mapMutations(['setStep', 'setLoading', 'setQuantityForCategory']),
    gtmTrackCheckout(callback) {
      const items = Object.keys(this.ticketsByCategory).map((categoryId) => {
        const cat = this.ticketCategories.find((tc) => tc.id === categoryId)
        const item = {
          item_id: categoryId.replace(
            `___${this.$store.state.validPromoCode}`,
            '',
          ),
          item_name: cat.attributes.title,
          price: parseFloat(cat.attributes.price),
          category: `${this.headEvent.attributes.slug} Ticket`,
          quantity: this.quantityForCategory(categoryId),
          termsAndConditionsAccepted: this.termsAndConditionsAccepted,
        }
        const discount = parseFloat(cat.attributes.discount_amount)
        if (discount) {
          item.coupon = cat.attributes.promo_code
          item.discount = discount
          item.price += discount // track original price
          // it's okay to redefine price here
          // because this object is only used for Google tracking
        }
        return item
      })

      if (this.$gtm.enabled()) {
        this.$gtm.trackEvent({
          event: 'checkout',
          eventTimeout: 2000,
          ecommerce: {
            items: null, // clear previous ecommerce object
            value: null,
            checkout: {
              actionField: { step: 'Choose Tickets' },
              items,
            },
          },
        })
      }
      callback()
    },
    discountedCategory(ticketCategoryID) {
      const discounted_id = `${ticketCategoryID}___${this.$store.state.validPromoCode}`
      return this.ticketCategories.find(
        (category) => category.id === discounted_id,
      )
    },
    discountedTicketsAvailable(discountedCategory) {
      if (discountedCategory) {
        return (
          this.quantityForCategory(discountedCategory.id) <
          discountedCategory.attributes.times_usable
        )
      }
    },
    showTicketCategory(ticketCategory) {
      if (ticketCategory.attributes.promo_code) {
        return true
      }

      const discounted = this.discountedCategory(ticketCategory.id)

      if (
        discounted &&
        (this.discountedTicketsAvailable(discounted) ||
          !discounted.attributes.times_usable)
      ) {
        this.setQuantityForCategory(ticketCategory.id, 0)
        return false
      }

      return true
    },
    quantityForCategory(categoryID) {
      if (this.$store.state.ticketsByCategory[categoryID] === undefined) {
        return 0
      }
      return this.$store.state.ticketsByCategory[categoryID].length
    },
    redirectForWebcompoenent() {
      const tickets = this.$store.getters.getTicketsQuantity
      const locale = this.webcomponentLocale
      const event = this.headEventSlug
      var queryString = `locale=${locale}&`

      queryString =
        queryString +
        Object.keys(tickets)
          .map((key) => `ticket_${key}=${tickets[key]}`)
          .join('&')
      if (this.validPromoCode) {
        queryString = `${queryString}&promo_code=${this.validPromoCode}`
      }

      const redirectString = `${urlFor(
        'ticketDetails',
        locale,
        event,
      )}?${queryString}`

      const self = this
      if (this.$gtm.enabled()) {
        this.gtmTrackCheckout(function () {
          self.setLoading(false)
          ;(window.location.href = redirectString), '_tab'
        })
      } else {
        this.setLoading(false)
        ;(window.location.href = redirectString), '_tab'
      }
    },
    async goToTickets() {
      if (!(await this.$validator.validate())) {
        this.validationErrorMessage = this.$t('errors.validationFailed')
        return
      }
      this.validationErrorMessage = null

      this.setLoading(true)
      if (this.isWebComponent) {
        this.redirectForWebcompoenent()
      } else {
        const locale = getLocale()
        const event = this.headEventSlug
        const self = this
        this.gtmTrackCheckout(function () {
          self.$router.push({
            name: 'Tickets',
            params: {
              locale: locale,
              event: event,
            },
            query: {
              termsAndConditionsAccepted: self.termsAndConditionsAccepted,
            },
          })
        })
      }
    },
    handleCodeSubmit() {
      this.updateTicketDiscounts(this.promoCode)
        .then(() => {
          this.showCodeInput = false
          this.$nextTick(() => this.$emit('ticketsReady'))
          document.getElementById('checkoutheader').scrollIntoView()
        })
        .catch((error) => {
          this.errors.add({
            field: 'promoCode',
            msg: error.response.data.errors[0].details,
          })
        })
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
  computed: {
    headEventClass() {
      return this.headEventSlug ? this.headEventSlug.toLowerCase() : null
    },
    continueToTicketsDetails() {
      return (
        this.$store.getters.anyTicketsChosen &&
        this.invalidTicketQuantities.length === 0
      )
    },
    promoCodeMessage() {
      return this.$t('ticketCategories.activePromocode', {
        code: this.validPromoCode,
      })
    },
    totalNet() {
      return this.$store.getters.getTotalNet
    },
    totalTaxes() {
      return this.$store.getters.getTotalTaxes
    },
    totalGross() {
      return this.$store.getters.getTotalGross
    },
    showTickets() {
      return this.ticketCategories && this.ticketCategories.length > 0
    },
    ...mapState([
      'ticketCategories',
      'inviteeToken',
      'headEventSlug',
      'ticketsByCategory',
      'config',
      'validPromoCode',
      'headEvent',
    ]),
  },
  mounted() {
    if (this.promoCode) {
      this.handleCodeSubmit()
    }
    this.$nextTick(() => this.$emit('ticketsReady'))
    this.setLoading(false)
    this.setStep(Constants.TICKET_CATEGORIES)
  },
}
</script>

<style lang="scss" scoped>
.omr-disclaimer {
  text-align: right;
  font-size: 14px;
  line-height: 30px;
  color: #666666;
}
</style>
