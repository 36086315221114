import Vue from 'vue'
import VueRouter from 'vue-router'
import CodeInvalid from './components/CodeInvalid.vue'
import QuotaUsedUp from './components/QuotaUsedUp.vue'
import QuotaExpired from './components/QuotaExpired.vue'
import Tickets from './components/Tickets.vue'
import TicketsChooser from './components/TicketsChooser.vue'
import TicketPartner from './components/TicketPartner.vue'
import InviteWrapper from './components/InviteWrapper.vue'
import { getLocale } from './locales/helper'
import store from './store/store'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import OidcCallback from './components/OidcCallback.vue'
import OidcCallbackError from './components/OidcCallbackError.vue'
import PageNotFound from './components/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/oidc-callback', // Needs to match redirect_uri in you oidcSettings
    name: 'oidcCallback',
    component: OidcCallback,
    meta: {
      isOidcCallback: true,
      isPublic: true,
    },
  },
  {
    path: `/oidc-callback-error`, // Needs to match redirect_uri in you oidcSettings
    name: 'oidcCallbackError',
    component: OidcCallbackError,
    meta: {
      isPublic: true,
    },
  },
  {
    path: `/:locale/:event/details`,
    props: true,
    name: 'Tickets',
    component: Tickets,
  },
  {
    path: `/:locale/code-invalid`,
    props: true,
    name: 'CodeInvalid',
    component: CodeInvalid,
  },
  {
    path: `/:locale/quota-used-up`,
    props: true,
    name: 'QuotaUsedUp',
    component: QuotaUsedUp,
  },
  {
    path: `/:locale/quota-expired`,
    props: true,
    name: 'QuotaExpired',
    component: QuotaExpired,
  },
  {
    path: `/${getLocale()}/:event`,
    name: 'TicketsChooser',
    props: true,
    meta: {
      isPublic: true,
    },
    component: TicketsChooser,
  },
  {
    path: `/${getLocale()}/partner/:slug`,
    name: 'TicketPartner',
    props: true,
    meta: {
      isPublic: true,
    },
    component: TicketPartner,
  },
  {
    path: `/${getLocale()}/invites/:token`,
    name: 'InviteWrapper',
    props: true,
    meta: {
      isPublic: true,
    },
    component: InviteWrapper,
  },
  {
    path: `/invites/:token`,
    redirect: `/${getLocale()}/invites/:token`,
    meta: {
      isPublic: true,
    },
  },
  {
    path: `/partner/:slug`,
    redirect: `/${getLocale()}/partner/:slug`,
    meta: {
      isPublic: true,
    },
  },
  {
    path: `*`, // Needs to match redirect_uri in you oidcSettings
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
      isPublic: true,
    },
  },
]
const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'))

export default router
