import Vue from 'vue'
import Vuex from 'vuex'
import Constants from '../services/constants'
import { i18n } from '../locales/helper'
import api from '../services/api'
import oidc from './modules/oidc'

function getRandomArbitrary(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

Vue.use(Vuex)
function getTotal(state, applyTaxes) {
  var totalPrice = 0
  for (let id in state.ticketsByCategory) {
    var tickets = state.ticketsByCategory[id]
    let foundIndex = state.ticketCategories.findIndex((x) => x.id === id)
    if (foundIndex > -1) {
      var ticketCategory = state.ticketCategories[foundIndex].attributes

      var ticketPriceInCents = Math.round(
        parseFloat(ticketCategory.price) * 100,
      )

      const applyQuantityDiscount =
        ticketCategory.promo_code === null &&
        ticketCategory.discount_amount === 0 &&
        Number.isInteger(ticketCategory.bulk_discount_activation_limit) &&
        ticketCategory.bulk_discount_activation_limit <= tickets.length

      if (applyQuantityDiscount) {
        ticketPriceInCents -= Math.round(
          (ticketPriceInCents / 100) * parseFloat(ticketCategory.bulk_discount),
        )
      }

      if (applyTaxes) {
        ticketPriceInCents = Math.round(ticketPriceInCents * (1 + state.vat))
      }

      totalPrice += (ticketPriceInCents / 100) * tickets.length
    }
  }
  return totalPrice
}

var store = new Vuex.Store({
  modules: { oidcStore: oidc },
  state: {
    ticketsByCategory: {},
    headEventSlug: null,
    step: Constants.TICKET_CATEGORIES,
    vat: Constants.DEFAULT_VAT,
    ticketCategories: [],
    genericError: null,
    validPromoCode: null,
    loading: false,
    headEvent: null,
    order_id: null,
    ticketPartner: null,
    paymentMethod: null,
    showFfwdConsent: false,
    config: null,
    inviteeToken: null,
    inviteeIsUsed: false,
    validTicketCategory: null,
    quotaExpired: null,
  },
  getters: {
    anyTicketsChosen: (state) =>
      Object.keys(state.ticketsByCategory).length > 0,
    getTicketsQuantity: (state) => {
      if (state.ticketCategories) {
        const ticketIDs = Object.keys(state.ticketsByCategory)
        var tickets = {}
        for (let h of ticketIDs) {
          tickets[h] = state.ticketsByCategory[h].length
        }
        return tickets
      } else {
        return null
      }
    },
    getTotalNet: (state) => {
      return getTotal(state, false)
    },
    getFfwdConsent: (state) => {
      return state.showFfwdConsent
    },
    getTotalGross: (state) => {
      return getTotal(state, true)
    },
    getTotalTaxes: (state, getters) => {
      return getters.getTotalGross - getters.getTotalNet
    },
    ticketAttributeValue:
      (state) => (ticketCategoryID, ticketID, attribute) => {
        var tickets = state.ticketsByCategory[ticketCategoryID]
        var foundIndex = tickets.findIndex((x) => x.id === ticketID)
        if (foundIndex > -1) {
          let value = tickets[foundIndex][attribute]
          return value ? value : ''
        } else {
          return
        }
      },
  },
  mutations: {
    initialiseStore(state) {
      if (sessionStorage.tickets) {
        this.replaceState(
          Object.assign(state, JSON.parse(sessionStorage.tickets)),
        )
      }
    },
    setOrderID(state, order_id) {
      state.order_id = order_id
    },
    setInviteeToken(state, token) {
      state.inviteeToken = token
    },
    setInviteeIsUsed(state, isUsed) {
      state.inviteeIsUsed = isUsed
    },
    setTicketPartner(state, ticketPartner) {
      state.ticketPartner = ticketPartner
    },
    setGenericError(state, message) {
      state.genericError = message
    },
    setConfig(state, config) {
      state.config = config
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    setHeadEvent(state, event) {
      state.headEvent = event
    },
    setStep(state, step) {
      state.step = step
    },
    setIsValidTicketCategory(state, validity) {
      state.validTicketCategory = validity
    },
    setIsQuotaExpired(state, isExpired) {
      state.quotaExpired = isExpired
    },
    setHeadEventSlug(state, slug) {
      state.headEventSlug = slug
    },
    setTicketCategories(state, ticketCategories) {
      state.ticketCategories = ticketCategories
    },
    setFfwdConsent(state, consent) {
      state.showFfwdConsent = consent
    },
    clearTicketsByCategory(state) {
      state.ticketsByCategory = {}
    },
    setValidPromoCode(state, promoCode) {
      state.validPromoCode = promoCode
    },
    setTicketAttribute(
      state,
      { attribute, value, ticketID, ticketCategoryID },
    ) {
      var tickets = state.ticketsByCategory[ticketCategoryID]
      var foundIndex = tickets.findIndex((x) => x.id === ticketID)
      if (foundIndex > -1) {
        tickets[foundIndex][attribute] = value
      }
      state.ticketsByCategory = {
        ...state.ticketsByCategory,
        [ticketCategoryID]: tickets,
      }
    },
    setPromoCodeOnTicket(state) {
      const ticketCategoryKeys = Object.keys(state.ticketsByCategory)
      for (let n in ticketCategoryKeys) {
        for (let i in state.ticketsByCategory[ticketCategoryKeys[n]]) {
          state.ticketsByCategory[ticketCategoryKeys[n]][i].promoCode =
            state.validPromoCode
        }
      }
    },
    distributeDiscountToTicketsByCategory(state) {
      for (let category of state.ticketCategories) {
        var id = category.id.split('___', 1)[0]
        var tickets = state.ticketsByCategory[id]
        if (!category.attributes.promo_code || !tickets) {
          continue
        }

        var maxTickets = category.attributes.times_usable || tickets.length
        var head = tickets.slice(0, maxTickets)
        var tail = tickets.slice(maxTickets, tickets.length)
        head = head.map((ticket) => {
          return { ...ticket, ticketCategoryID: category.id }
        })

        if (tail.length > 0) {
          state.ticketsByCategory = {
            ...state.ticketsByCategory,
            [category.id]: head,
            [id]: tail,
          }
        } else {
          delete state.ticketsByCategory[id]
          state.ticketsByCategory = {
            ...state.ticketsByCategory,
            [category.id]: head,
          }
        }
      }
    },
    setQuantityForCategory(state, { ticketCategoryID, quantity }) {
      if (quantity > 0) {
        var tickets = []
        var existingTickets =
          state.ticketsByCategory[ticketCategoryID] || tickets
        var foundIndex = state.ticketCategories.findIndex(
          (x) => x.id === ticketCategoryID,
        )

        var maxQuantity = 100

        if (foundIndex > -1) {
          let times_usable =
            state.ticketCategories[foundIndex].attributes.times_usable
          maxQuantity =
            times_usable === null || times_usable === undefined
              ? 100
              : times_usable
        }

        if (
          existingTickets.length <
          (quantity < maxQuantity ? quantity : maxQuantity)
        ) {
          const ticketCategory = state.ticketCategories.find(
            (c) => c.id === ticketCategoryID,
          )
          for (let index = tickets.length; index < quantity; index++) {
            let newTicket = {
              id: `${index}_${ticketCategoryID}_${getRandomArbitrary(0,99999)}`,
              promoCode: ticketCategory.attributes.promo_code,
              ticketCategoryID: ticketCategoryID,
              firstName: '',
              email: '',
              phone: '',
              lastName: '',
              salutation: '',
              title: '',
              companyType: '',
              companyName: '',
              companySize: '',
              jobLevel: '',
              position: '',
              companyFieldId: '',
              companyIndustryId: '',
              ownership: '',
              omrMatchConsent: false,
            }
            if (ticketCategory.attributes.promo_code) {
              // promo code is fake token
              // when actually creating ticket the real code is loaded through the invitee token
              newTicket.inviteeToken = state.inviteeToken
            }
            tickets.push(newTicket)
          }
        } else {
          tickets = existingTickets.slice(0, quantity)
        }
        state.ticketsByCategory = {
          ...state.ticketsByCategory,
          [ticketCategoryID]: tickets,
        }
      } else {
        Vue.delete(state.ticketsByCategory, ticketCategoryID)
      }
    },
    handleRequestError(state, error) {
      if (error.response === undefined)
        state.genericError = i18n.t('errors.genericError')
      else if (error.response.status === 404)
        state.genericError = i18n.t('errors.notFound')
    },
  },
  actions: {
    requestOrder({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .createOrder(
            state.ticketsByCategory,
            state.validPromoCode,
            state.inviteeToken,
            state.order_id,
            state.oidcStore.access_token,
          )
          .then((response) => {
            const data = response.data
            commit('setOrderID', data.order_id)
            commit('setGenericError', null)
            if (data.free_checkout_token) {
              api
                .checkoutForFree(data.free_checkout_token)
                .then((response) => resolve(response.data.redirect_url))
                .catch((error) => {
                  commit('handleRequestError', error)
                  reject(error)
                })
            } else {
              resolve(data.checkout_url)
            }
          })
          .catch((error) => {
            commit('handleRequestError', error)
            commit('setLoading', false)
            reject(error)
          })
      })
    },
    updateTicketDiscounts({ commit, dispatch }, promoCode) {
      return new Promise((resolve, reject) => {
        dispatch('fetchTicketCategories', promoCode)
          .then(() => {
            commit('setGenericError', null)
            commit('distributeDiscountToTicketsByCategory')
            commit('setPromoCodeOnTicket')
            resolve(true)
          })
          .catch((error) => reject(error))
      })
    },
    fetchTicketPartner({ commit, state }, slug) {
      return api
        .getTicketPartner(slug, state.inviteeToken)
        .then((response) => {
          commit('setTicketPartner', response.data.data)
          if (response.data.data.attributes.promo_code) {
            commit(
              'setValidPromoCode',
              response.data.data.attributes.promo_code,
            )
          }
          commit('setLoading', false)
          return response.data.data.attributes
        })
        .catch((error) => {
          commit('setLoading', false)
          commit('handleRequestError', error)
          commit('setTicketPartner', null)
          return null
        })
    },
    fetchHeadEvent({ commit }, eventSlug) {
      return new Promise((resolve, reject) => {
        api
          .getHeadEvent(eventSlug)
          .then((response) => {
            commit('setGenericError', null)
            commit('setHeadEventSlug', eventSlug)
            commit('setHeadEvent', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchConfig({ commit, state }) {
      api
        .getConfig(state.oidcStore.access_token)
        .then((response) => {
          commit('setGenericError', null)
          commit('setConfig', response.data)
        })
        .catch((error) => commit('handleRequestError', error))
    },
    async fetchTicketCategories({ commit, state }, promoCode) {
      return new Promise((resolve, reject) => {
        api
          .getTicketCategories({
            promo_code: promoCode,
            invitee_token: state.inviteeToken,
            head_event: state.headEventSlug,
          })
          .then((response) => {
            commit('setGenericError', null)
            commit('setTicketCategories', response.data.data)
            if (response.data.meta.code) {
              commit('setValidPromoCode', response.data.meta.code)
            }
            commit('setLoading', false)
            commit('setIsValidTicketCategory', true)
            if (response.data.data[0].attributes.fields.includes('ffwd')) {
              commit('setFfwdConsent', true)
            }
            if (response.data.data[0].attributes.expired)
              commit('setIsQuotaExpired', true)
            resolve(response)
          })
          .catch((error) => {
            if (error.response.status !== 404)
              commit('handleRequestError', error)
            if (
              error.response.status === 404 &&
              error.response.data.errors[0].title === 'Code is invalid'
            ) {
              commit('setIsValidTicketCategory', false)
            }
            commit('setLoading', false)
            reject(error)
          })
      })
    },
  },
})

store.subscribe((_mutation, state) => {
  sessionStorage.setItem('tickets', JSON.stringify(state))
})

store.commit('initialiseStore')

export default store
